import { Controller } from "stimulus"
import toastr from '../src/javascripts/nowsignage/toastr.min';
export default class extends Controller {

  //Toastr library: https://github.com/CodeSeven/toastr
  // Controller use:
  // Create a DOM element with
  // class: toast-success or toast-error
  // data-controller: 'toast-notifications'
  // data-toast-text: Text to be used in the toast message

  initialize() {
    this.toastPosition = this.data.get('toastPosition') || 'toast-top-right';

    toastr.options = {
      "closeButton": true,
      "progressBar": true,
      "positionClass": this.toastPosition,
      "timeOut": "3000",
    }

    if (this.element.classList.contains('toast-success')) {
      this.loadSuccessToast()
    }

    if (this.element.classList.contains('toast-error')) {
      this.loadErrorToast()
    }
  }

  loadSuccessToast() {
    toastr.success(this.element.getAttribute('data-toast-text'))
  }

  loadErrorToast() {
    toastr.error(this.element.getAttribute('data-toast-text'))
  }
}