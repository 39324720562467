import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    bulkArtworkUrl: String,
    bulkArtworkApprovalUrl: String
  };
  static targets = ["asset", "form", "button", "assetTypeInput", "assetIdInput", "selected",
    "selectedCount", "editButton", "approvalButton", "actionsContainer"]

  initialize() {
    window.recordIds = [];
  }

  connect() {
    if(this.hasButtonTarget){ this.disableButton() }
  }

  selectImage(event) {
    const element = event.target.parentNode
    let selectedAsset = this.assetTargets.find(e => e.classList.contains('active'));

    if (selectedAsset && selectedAsset != element) {
      selectedAsset.classList.remove('active')
      selectedAsset.classList.remove('border-blue-500')
      selectedAsset.classList.add('border-white')
    }

    element.classList.toggle('active')
    element.classList.toggle('border-blue-500')
    element.classList.toggle('border-white')
    if(element.classList.contains('active')){
      this.enableButton();
      this.updateForm(element);
    } else {
      this.disableButton();
      this.updateForm(null);
    }
  }

  disableButton() {
    this.buttonTarget.disabled = true;
    this.buttonTarget.classList.add('disabled', 'cursor-not-allowed');
    this.buttonTarget.style.opacity = "0.7";
  }

  enableButton() {
    this.buttonTarget.disabled = false;
    this.buttonTarget.classList.remove('disabled', 'cursor-not-allowed');
    this.buttonTarget.style.opacity = "1";
  }

  updateForm(element){
    this.assetTypeInputTarget.value = element ? element.dataset.assetType : null;
    this.assetIdInputTarget.value = element ? element.dataset.assetId : null;
  }

  checkbox() {
    const ids = event.target.value.split(',');

    if (event.target.checked) {
      window.recordIds.push(ids);
      if (ids.length > 1) {
        this.checkboxMuliple(ids, true);
      }
    } else {
      ids.forEach(id => {
        var index = window.recordIds.indexOf(id);
        if (index !== -1) {
          window.recordIds.splice(index, 1);
        }
        this.checkboxMuliple(ids, false);
      })
    }
    this.updateIdsUrl();
    this.setCounter();
  }

  checkboxMuliple(ids, checked) {
    this.selectedTargets.forEach(target => {
      if (ids.includes(target.value)) {
        target.checked = checked;
      }
    });
  }

  populateIds() {
    this.selectedTargets.forEach(target => {
      if (target.checked) {
        window.recordIds.push(parseInt(target.value))
      }
    });
    this.updateIdsUrl();
  }

  updateIdsUrl() {
    if (window.recordIds.length === 0) {
      if (this.hasEditButtonTarget) {
        this.editButtonTarget.href = '';
        this.editButtonTarget.style.opacity = 0.7;
        this.editButtonTarget.classList.add('cursor-not-allowed');
      }
      if (this.hasApprovalButtonTarget) {
        this.approvalButtonTarget.href = '';
        this.approvalButtonTarget.style.opacity = 0.7;
        this.approvalButtonTarget.classList.add('cursor-not-allowed');
      }
    } else {
      if (this.hasEditButtonTarget) {
        const editUrl = this.bulkArtworkUrlValue + '?record_ids=' + window.recordIds;

        this.editButtonTarget.href = editUrl;
        this.editButtonTarget.style.opacity = 1;
        this.editButtonTarget.classList.remove('cursor-not-allowed');
      }
      if (this.hasApprovalButtonTarget) {
        const approvalUrl = this.bulkArtworkApprovalUrlValue + '?record_ids=' + window.recordIds;

        this.approvalButtonTarget.href = approvalUrl;
        this.approvalButtonTarget.style.opacity = 1;
        this.approvalButtonTarget.classList.remove('cursor-not-allowed');
      }
    }
  }

  setCounter() {
    window.recordIds = [...new Set(window.recordIds.flat())];

    const new_value = window.recordIds.length;
    this.selectedCountTarget.textContent = new_value;
    if(new_value === 0) {
      this.actionsContainerTarget.classList.add('hidden');
    } else {
      this.actionsContainerTarget.classList.remove('hidden');
    }
  }
}
