import { Controller } from "stimulus"

export default class extends Controller {

  static values = {
    objectsIds: Array,
    selectAll: Boolean,
    overridesUrl: String,
    revertsUrl: String,
    selectAll: Boolean
  }

  static targets = [ "selectedCount" ]

  initialize() {
    window.ids = this.objectsIdsValue;
  }

  checkbox(){
    const id = parseInt(event.target.value)
    if (event.target.checked) {

      if (!window.ids.includes(id)){
        window.ids.push(id);
      }

      this.increaseCounter();
      
    } else {
      var index = window.ids.indexOf(id);
      if (index !== -1) {
        window.ids.splice(index, 1);
      }
      this.decreaseCounter();
    }
  }

  increaseCounter() {
    this.selectedCountTarget.textContent = parseInt(this.selectedCountTarget.textContent) + 1
  }

  decreaseCounter() {
    this.selectedCountTarget.textContent = parseInt(this.selectedCountTarget.textContent) - 1
  }

  massEdit() {
    let url = this.overridesUrlValue;

    (window.ids.length > 0) ? url = url + '?menu_board_ids=' + window.ids : null;
    (this.selectAllValue == true) ? url = url + '?select_all=' + true : null;

    if (window.ids.length == 0 && this.selectAllValue != true){
      alert("Please select elements to perform any action");
    } else {
      window.location = url;
    }
  }

  massRevert() {
    let url = this.revertsUrlValue;

    (window.ids.length > 0) ? url = url + '?menu_board_ids=' + window.ids : null;
    (this.selectAllValue == true) ? url = url + '?select_all=' + true : null;

    if (window.ids.length == 0 && this.selectAllValue != true){
      alert("Please select elements to perform any action");
    } else {
      window.location = url;
    }
  }
}
