import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["nameInput", "emailInput", "passwordInput", "confirmationPasswordInput", "length", "lower", "upper",
    "number", "special", "button", "passwordMatch", "termsAndConditionsInput", "passwordError"]

  connect() {
    this.validateForm();
  }

  validateForm() {
    const name = this.hasNameInputTarget ? this.validateName() : true;
    const email = this.hasEmailInputTarget ? this.validateEmail() : true;
    const termsAndConditions = this.hasTermsAndConditionsInputTarget ? this.validateTermsAndConditions() : true;
    const password = this.validatePassword();

    if (this.hasButtonTarget) {
      (password && name && email && termsAndConditions) ? this.enableButton() : this.disableButton();
    }
  }

  validateName() {
    const validated = (this.nameInputTarget.value === '') ? false : true

    if(validated){
      this.nameInputTarget.classList.remove('border-red-300', 'text-red-900', 'placeholder-red-300', 'focus:outline-none', 'focus:ring-red-500', 'focus:border-red-500')
      this.nameInputTarget.classList.add('border-green-300')
    } else if(this.nameInputTarget.value !== '') {
      this.nameInputTarget.classList.add('border-red-300', 'text-red-900', 'placeholder-red-300', 'focus:outline-none', 'focus:ring-red-500', 'focus:border-red-500')
    }

    return validated
  }

  validateEmail() {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const validated = (this.emailInputTarget.value.match(validRegex)) ? true : false

    if(validated){
      this.emailInputTarget.classList.remove('border-red-300', 'text-red-900', 'placeholder-red-300', 'focus:outline-none', 'focus:ring-red-500', 'focus:border-red-500')
      this.emailInputTarget.classList.add('border-green-300')
    } else if(this.emailInputTarget.value !== '') {
      this.emailInputTarget.classList.add('border-red-300', 'text-red-900', 'placeholder-red-300', 'focus:outline-none', 'focus:ring-red-500', 'focus:border-red-500')
    }

    return validated
  }

  validatePassword() {
    if(this.passwordInputTarget.value === ''){
      return false;
    }

    let number = this.hasNumbers(this.passwordInputTarget.value);
    let length = this.hasLength(this.passwordInputTarget.value);
    let lower = this.hasLowers(this.passwordInputTarget.value);
    let upper = this.hasUppers(this.passwordInputTarget.value);
    let special = this.hasSpecial(this.passwordInputTarget.value);

    number ? this.check('number') : this.uncheck('number');
    length ? this.check('length') : this.uncheck('length');
    lower ? this.check('lower') : this.uncheck('lower');
    upper ? this.check('upper') : this.uncheck('upper');
    special ? this.check('special') : this.uncheck('special');

    const validated = (number && length && lower && upper && special) ? true : false;

    if(validated){
      this.passwordInputTarget.classList.remove('border-red-300', 'text-red-900', 'placeholder-red-300', 'focus:outline-none', 'focus:ring-red-500', 'focus:border-red-500')
      this.passwordInputTarget.classList.add('border-green-300')
      this.passwordErrorTarget.classList.add('hidden')
    } else if(this.passwordInputTarget.value !== '') {
      this.passwordInputTarget.classList.add('border-red-300', 'text-red-900', 'placeholder-red-300', 'focus:outline-none', 'focus:ring-red-500', 'focus:border-red-500')
      this.passwordErrorTarget.classList.remove('hidden')
    }

    return validated
  }

  validateTermsAndConditions() {
    return this.termsAndConditionsInputTarget.checked ? true : false;
  }

  findTarget(name) {
    let target = null;

    switch(name){
    case 'number':
      target = this.numberTarget;
      break;
    case 'length':
      target = this.lengthTarget;
      break;
    case 'upper':
      target = this.upperTarget;
      break;
    case 'lower':
      target = this.lowerTarget;
      break;
    case 'special':
      target = this.specialTarget;
      break;
    }

    return target;
  }

  check(name) {
    const target = this.findTarget(name)
    target.classList.remove('hidden')

    if(target.classList.contains('text-red-400') || target.classList.contains('text-gray-400')){
      target.querySelectorAll('svg').forEach(icon => icon.classList.toggle('hidden'))
    }

    target.classList.remove('text-red-400')
    target.classList.remove('text-gray-400')
    target.classList.add('text-green-600')
  }

  uncheck(name) {
    const target = this.findTarget(name)
    target.classList.remove('hidden')

    if(target.classList.contains('text-green-600')){
      target.querySelectorAll('svg').forEach(icon => icon.classList.toggle('hidden'))
    }

    target.classList.add('text-red-400')
    target.classList.remove('text-gray-400')
    target.classList.remove('text-green-600')
  }

  hasNumbers(str) {
    return (str.match(/\d+/g) !== null) ? true : false;
  }

  hasLowers(str) {
    return (str.match(/[a-z]/) !== null) ? true : false;
  }

  hasUppers(str) {
    return (str.match(/[A-Z]/) !== null) ? true : false;
  }

  hasSpecial(str) {
    const specialChars = /[#?!@$%^&*._=+<>\-~]/;
    return specialChars.test(str);
  }

  hasLength(str) {
    return (str.length >= 8) ? true : false;
  }

  disableButton() {
    this.buttonTarget.disabled = true;
    this.buttonTarget.classList.add('disabled', 'cursor-not-allowed');
    this.buttonTarget.style.opacity = "0.7";
  }

  enableButton() {
    this.buttonTarget.disabled = false;
    this.buttonTarget.classList.remove('disabled', 'cursor-not-allowed');
    this.buttonTarget.style.opacity = "1";
  }
}
