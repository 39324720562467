import { Controller } from "stimulus"

export default class extends Controller {

  static values = {}

  static targets = ["zoneFields" ]

  initialize() {
  }

  toggleZoneFields(){
    this.hideZoneFields();
    let zoneId = event.currentTarget.id;

    const fieldsForm = document.getElementById(`fields-${zoneId}`);
    if (fieldsForm){
      fieldsForm.classList.remove('hidden');
    }
  }

  hideZoneFields(){
    this.zoneFieldsTargets.forEach(target => {
      target.classList.add('hidden');
    });
  }

  disablePageLinks(){
    const links = document.links;

    for (var i = 0; i < links.length; i++) {
      links[i].onclick = function() {return false;};
      links[i].setAttribute('disabled', 'disabled');
      links[i].setAttribute('data-action', '');
    }
  }
}
