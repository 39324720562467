import { Controller } from "stimulus"
import Assets from '../src/javascripts/nowsignage/assets';

export default class extends Controller {
  static values = {
    profileSlug: String,
    editUrl: String,
    deleteUrl: String,
    folderId: Number
  };
  static targets = [ "selected", "selectedCount", "editButton", "deleteButton", "actionsContainer", "newDropzone",
    "assetsList", "dropzoneActions" ]

  initialize() {
    window.imageIds = [];
    window.videoIds = [];
    window.folderIds = [];
    if(this.hasNewDropzoneTarget) {
      window.Assets.newUiInitializeDropzone(this.profileSlugValue, this.newDropzoneTarget.id);
      
      if (this.hasDropzoneActionsTarget) {
        this.fitDropzoneHeight();
      }
    }
    if (this.hasEditButtonTarget) {
      this.populateIds();  
    } 
  }

  fitDropzoneHeight() {
    const dropzoneOffset = this.newDropzoneTarget.getBoundingClientRect();
    const assetsListHeight = this.newDropzoneTarget.offsetHeight;

    const minHeight = (window.innerHeight - dropzoneOffset.top)
    
    if(this.newDropzoneTarget.dataset.noAssets == 'true' || minHeight > assetsListHeight){
      this.newDropzoneTarget.style.height = minHeight + 'px'
    }
  }

  dragStart(e) {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer.types && e.dataTransfer.types.includes('Files')){
      this.newDropzoneTarget.style.visibility = 'visible'
      this.dropzoneActionsTarget.style.visibility = 'visible'
      this.dropzoneActionsTarget.style.opacity = 1
    }
  }

  dragEnd(e) {
    e.stopPropagation();
    e.preventDefault();
    if (this.hasAssetsListTarget && !document.querySelector('.dz-details')) {
      this.newDropzoneTarget.style.visibility = 'hidden'
      this.dropzoneActionsTarget.style.visibility = 'visible'
      this.dropzoneActionsTarget.style.opacity = 0.7
    }
  }

  checkbox() {
    const id = parseInt(event.target.value);

    if (event.target.checked) {
      switch (event.target.dataset.assetType) {
        case 'image':
          window.imageIds.push(id);
          break;
        case 'video':
          window.videoIds.push(id);
        case 'folder':
          window.folderIds.push(id);
          break;
      }
      this.increaseCounter();
    } else {
      switch (event.target.dataset.assetType) {
        case 'image':
          var index = window.imageIds.indexOf(id);
          if (index !== -1) {
            window.imageIds.splice(index, 1);
          }
          break;
        case 'video':
          var index = window.videoIds.indexOf(id);
          if (index !== -1) {
            window.videoIds.splice(index, 1);
          }
          break
        case 'folder':
          var index = window.folderIds.indexOf(id);
          if (index !== -1) {
            window.folderIds.splice(index, 1);
          }
          break;
      }
      this.decreaseCounter();
    }
    this.updateIdsUrl();
  }

  populateIds() {
    this.selectedTargets.forEach(target => {
      if (target.checked) {
        if (target.dataset.assetType == 'image') {
          window.imageIds.push(parseInt(target.value))
        } else if (target.dataset.assetType == 'video') {
          window.videoIds.push(parseInt(target.value))
        } else {
          window.folderIds.push(parseInt(target.value))
        }
      }
    });
    this.updateIdsUrl();
  }

  updateIdsUrl() {
    if (window.imageIds.length === 0 && window.videoIds.length === 0 && window.folderIds.length === 0) {
      this.editButtonTarget.href = '';
      this.deleteButtonTarget.href = '';
      this.editButtonTarget.style.opacity = 0.7;
      this.deleteButtonTarget.style.opacity = 0.7;
      this.editButtonTarget.classList.add('cursor-not-allowed');
      this.deleteButtonTarget.classList.add('cursor-not-allowed');
    } else {
      const editUrl = this.editUrlValue + '?image_ids=' + window.imageIds + '&video_ids=' + window.videoIds + '&folder_ids=' + window.folderIds + '&folder_id=' + this.folderIdValue;
      const deleteUrl = this.deleteUrlValue + '?image_ids=' + window.imageIds + '&video_ids=' + window.videoIds + '&folder_ids=' + window.folderIds + '&folder_id=' + this.folderIdValue;
      this.editButtonTarget.href = editUrl;
      this.deleteButtonTarget.href = deleteUrl;
      this.editButtonTarget.style.opacity = 1;
      this.deleteButtonTarget.style.opacity = 1;
      this.editButtonTarget.classList.remove('cursor-not-allowed');
      this.deleteButtonTarget.classList.remove('cursor-not-allowed');
    }
  }

  increaseCounter() {
    this.actionsContainerTarget.classList.remove('hidden');
    this.selectedCountTarget.textContent = parseInt(this.selectedCountTarget.textContent) + 1
  }

  decreaseCounter() {
    const new_value = parseInt(this.selectedCountTarget.textContent) - 1
    this.selectedCountTarget.textContent = new_value
    if(new_value === 0) {
      this.actionsContainerTarget.classList.add('hidden');
    }
  }
}