import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "item"]

  search(e) {
    if (e.key === "Enter") { 
      e.preventDefault()
      return 
    }

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      const searchTerm = e.target.value.trim();

      this.itemTargets.forEach((target) => {
        const itemName = target.dataset.name.toLowerCase();

        if (itemName.includes(searchTerm)) {
          target.classList.remove("hidden");
        } else {
          target.classList.add("hidden");
        }
      });
    }, 1000);
  }
}