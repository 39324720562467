import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["previewButton", "previewItems"]

  loopSelected() {
    this.previewButtonTarget.classList.remove("hidden");

    if (event.target.previousSibling.previousSibling.value) {
      this.previewItemsTargets.forEach((el, i) => {
        if (event.target.previousSibling.previousSibling.value == el.getAttribute('data-id')) {
          el.classList.remove("hidden");
        } else {
          el.classList.add("hidden");
        }
      })
    }
  }
}