import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["noImage", "uploadImage", "stockImage"]

  initialize() {
    console.log('social_image_uploads_controller init');
    this.disableButton();
  }

  imageSelect() {
    console.log('img selected' + event.target.value);
    const tabs = document.getElementsByClassName("image-tab");

    for (let i = 0; i < tabs.length; i++) {
      tabs[i].classList.add('hidden');
    }

    this.clearStockImageSelection();

    switch (event.target.value) {
      case 'no_image':
        this.noImageTarget.classList.remove('hidden');
        console.log('social_image_wizard[no_image] to TRUE');
        document.getElementsByName("social_image_wizard[no_image]")[0].value = true;
        this.enableButton();
        break;
      case 'upload_image':
        this.uploadImageTarget.classList.remove('hidden');
        this.clearNoImageInput()
        this.disableButton();
        break;
      case 'stock_image':
        this.stockImageTarget.classList.remove('hidden');
        this.clearNoImageInput()
        this.disableButton();
        break;
      default:
      // code block
    }
  }

  clearStockImageSelection() {
    console.log('clearing stock image selection');
    var ele = document.getElementsByName("social_image_wizard[stock_image_id]");
    for (var i = 0; i < ele.length; i++) {
      ele[i].checked = false;
      ele[i].parentElement.classList.add('border-bg-gray-200');
      ele[i].parentElement.classList.remove('border-color-primary');
    }
  }

  clearNoImageInput() {
    console.log('social_image_wizard[no_image] to FALSE');
    document.getElementsByName("social_image_wizard[no_image]")[0].value = false;
  }

  stockImageSelected() {
    var ele = document.getElementsByName("social_image_wizard[stock_image_id]");
    for (var i = 0; i < ele.length; i++) {
      ele[i].parentElement.classList.remove('border-color-primary');
      ele[i].parentElement.classList.add('border-bg-gray-200');
    }

    event.target.parentElement.classList.add('border-color-primary');
    this.enableButton();
  }

  enableButton() {
    console.log('enable continue');
    document.getElementById('action-save-social-image').disabled = false;
    document.getElementById('action-save-social-image').style.opacity = "1";
  }

  disableButton() {
    console.log('disable continue');
    document.getElementById('action-save-social-image').disabled = true;
    document.getElementById('action-save-social-image').style.opacity = "0.7";
  }
}