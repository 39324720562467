import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["submitButton"]
  static values = {
    key: String,
    name: String
  }

  initialize() {
    window.key_array = []
    window.command_name = '';

    this.handleEdit();
  }

  handleEdit() {
    if (this.keyValue && this.nameValue) {
      key_array = this.keyValue.split('.');
      command_name = this.nameValue;

      this.showTemperatureSelector();
      this.showConditionSelector();
      this.enableSubmitButton();
    }
  }

  nameInput() {
    command_name = event.target.value;
    if (command_name === '') {
      this.hideTemperatureSelector();
    } else {
      this.showTemperatureSelector()
    }
  }

  showTemperatureSelector() {
    document.getElementById('temperature-select-msg').classList.remove("hidden");

    // on edit -> populate field
    if (key_array[0]) {
      document.getElementById('temperature-select').value = key_array[0]
    }
  }

  hideTemperatureSelector() {
    this.hideConditionSelector();
    key_array.length = 0;// remove further values

    document.getElementById('temperature-select-msg').classList.add("hidden");
    document.getElementById('temperature-select-msg').value = '';
  }

  temperatureSelected() {
    const temp = event.target.value;

    if (temp == '') {
      this.hideConditionSelector();
    } else {
      key_array[0] = temp;

      this.showConditionSelector();
      this.enableSubmitButton();
    }
    // console.log(key_array);
  }

  showConditionSelector() {
    document.getElementById('condition-select-msg').classList.remove("hidden");

    // on edit -> populate field
    if (key_array[1]) {
      document.getElementById('condition-select').value = key_array[1]
    }
  }

  hideConditionSelector() {
    key_array.length = 1;// remove further values

    document.getElementById('condition-select-msg').classList.add("hidden");
    document.getElementById('condition-select-msg').value = '';
  }

  conditionSelected() {
    const condition = event.target.value;
    key_array[1] = condition;
    // console.log(key_array);
  }

  enableSubmitButton() {
    if (command_name != '') {
      this.submitButtonTarget.removeAttribute('disabled');
    }
  }
  disableSubmitButton() {
    this.submitButtonTarget.setAttribute('disabled', 'disabled');
  }

  submit() {
    var keys_array_form = document.getElementById('key-form');
    keys_array_form.value = key_array.filter(e => e).join('.'); //remove empty strings

    var name_form = document.getElementById('name-form');
    name_form.value = command_name;

    var form = document.getElementById('trigger-command-form');
    form.submit();
  }

}