import { Controller } from "stimulus"
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {

  static values = {
    profileId: String,
    menuBoardGroupId: Number,
    menuBoardId: Number,
  }

  async zoneClick(event) {
    const profileId = this.profileIdValue;
    const mbgId = this.menuBoardGroupIdValue;
    const mbId = this.menuBoardIdValue;

    const layoutZoneId = event.currentTarget.id.split('-').pop(); //clicked zone ID

    // menu board hidden form fields
    const mbpFields = document.getElementById(`mbp-zone-${layoutZoneId}`);
    
    const mbpId = mbpFields.dataset.mbpId;
    const productId = mbpFields.querySelector('.product-id').value;
    const outStock = mbpFields.querySelector('.out-stock').value;

    // console.log('profile : ' + profileId + ', mbgId : ' + mbgId + ', mbId : ' + mbId + ', mbpId : ' + mbpId + ', productId : ' + productId + ', outStock : ' + outStock)

    const request = new FetchRequest('GET', format("/odc/profiles/{0}/dynamic_pricing/menu_board_groups/{1}/menu_boards/{2}/menu_board_products/{3}/edit?productId={4}&outStock={5}", profileId, mbgId, mbId, mbpId, productId, outStock),
    {
      responseKind: "turbo-stream"
    });

    const response = await request.perform();    
  }

  mbpSubmit(event) {
    event.preventDefault();
    // get values from form
    const layoutZoneId = document.getElementById('layoutZoneId').value
    const outStockCheckbox = document.getElementById('outStockCheckbox').checked

    let productIdSelected = null;
    if(document.getElementById('productIdSelect')){
      productIdSelected = document.getElementById('productIdSelect').value
    }

    // menu board hidden form fields
    const mbpFields = document.getElementById(`mbp-zone-${layoutZoneId}`);

    if (productIdSelected){
      mbpFields.querySelector('.product-id').value = productIdSelected;
    }

    mbpFields.querySelector('.out-stock').value = outStockCheckbox;
    const menuBoardForm = document.getElementById('menuBoardForm')
    menuBoardForm.requestSubmit();
  }

  updateMenuBoard() {
    // on menu board update click
    let confirmed = confirm(event.currentTarget.dataset.confirmText);

    if (confirmed){
      const profileId = this.profileIdValue;
      const mbgId = this.menuBoardGroupIdValue;
      const mbId = this.menuBoardIdValue;
      const updateUrl = `/odc/profiles/${profileId}/dynamic_pricing/menu_board_groups/${mbgId}/menu_boards/${mbId}`

      const menuBoardForm = document.getElementById('menuBoardForm');
      menuBoardForm.action = updateUrl;
      menuBoardForm.method = "PATCH";

      menuBoardForm.requestSubmit();
    }
  }

  revertMenuBoard() {
    // on menu board revert click
    let confirmed = confirm(event.currentTarget.dataset.confirmText)

    if (confirmed){
      window.location.reload();
    }
  }
}