import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  initialize() {
    this.imagesTabChange();
  }

  imagesTabChange() {
    const activeTab = document.querySelector("[data-tabs-target='tab']:not(.hidden)");
    const locale = activeTab.dataset.targetId.split("-")[1];

    document.querySelectorAll(".translatable-image-tab").forEach(function(tab) {
      if (tab.id == `locale-image-${locale}`) {
        tab.classList.remove('hidden');
      } else {
        tab.classList.add('hidden');
      }
    });
  }
}