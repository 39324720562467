import { Controller } from "stimulus"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static targets = ["input"];

  activateGridView(event) {
    if(!event.target.classList.contains('active')) {
      this.toggleButtonStyle();  
    }
    this.updateCustomerSetting("grid");
  }

  activateListView(event) {
    if(!event.target.classList.contains('active')) {
      this.toggleButtonStyle();  
    }
    this.updateCustomerSetting("list");
  }

  toggleButtonStyle() {
    this.inputTargets.forEach(target => {
      target.classList.toggle('bg-blue-300')
      target.classList.toggle('text-white')
      target.classList.toggle('hover:bg-blue-300')
      target.classList.toggle('hover:text-white')
      target.classList.toggle('active')
    });
  }

  async updateCustomerSetting(viewFormat) {
    const request = new FetchRequest("patch", "/customer", {
      body: {
        customer: {
          view_format: viewFormat
        }
      },
      responseKind: "json"
    })
    const response = await request.perform()
  }
}
