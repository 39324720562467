import { Controller } from "stimulus"

export default class extends Controller {

  static values = { with: String }

  connect() {
    const form = this.element.closest("form");

    this.element.addEventListener("click", () => {
      this.element.disabled = true;
      this.element.innerHTML = this.withValue;
      
      if (form) {
        form.requestSubmit();
      }
    });
  }
}