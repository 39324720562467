import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["openMenuButton", "closeMenuButton", "sidebarMenu", "compactMenuButton"]
  
  toggleMenu() {
    document.body.classList.toggle('fixed');
    this.sidebarMenuTarget.classList.toggle('open');
    this.openMenuButtonTarget.classList.toggle('display-none');
    this.closeMenuButtonTarget.classList.toggle('display-none');
  }

  toggleCompactVersion() {
    this.sidebarMenuTarget.classList.toggle('compact-version');
  }
}
