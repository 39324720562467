import { Controller } from "stimulus"
import { FetchRequest } from "@rails/request.js"


export default class extends Controller {
  static targets = ['submitBtn', 'dayInput', 'fromTimeInput', 'toTimeInput', 'timeConfictBanner', 'playlistInput', 'layoutInput', 'selectAllDaysButton']
  static values = { scheduleId: Number, profileId: String, timeslotId: Number }

  async checkTimeConflict() {
    const schedule_id = this.scheduleIdValue;
    const profile_cached_slug = this.profileIdValue;
    const url = `/profiles/${profile_cached_slug}/schedules/${schedule_id}/timeslot_conflicts`
    const days = []
    const from =  this.fromTimeInputTarget.value
    const to =  this.toTimeInputTarget.value

    this.dayInputTargets.forEach(target => {
      if(target.checked) {
        days.push(target.value)
      }
    });

    if (days.length != 7 ) {
      this.enableSelectAllButton();
    }

    if (days.length == 0 || (from == null || from == "") || (to == null || to == "")) {
      this.disableButton(false)
    } else {
      const request = new FetchRequest("post", url, {
        body: JSON.stringify(
          {
            days: days,
            from: from,
            to: to,
            timeslot_id: this.timeslotIdValue
          }
        ),
        responseKind: "json"
      });


      const response = await request.perform();

      if (response.ok) {
        const body = await response.text;
        const json = JSON.parse(body);

        if(json.error) {
          this.disableButton(true);
        } else {
          this.enableButton();
        }
      }
    }
  }

  uncheckPlaylists() {
    this.playlistInputTargets.forEach((el) => {
      el.value = '';
    });
  }

  uncheckLayouts() {
    this.layoutInputTargets.forEach((el) => {
      el.checked = false;
    });
  }

  disableButton(conflict) {
    this.submitBtnTarget.disabled = true;
    this.submitBtnTarget.classList.add('disabled');
    this.submitBtnTarget.style.opacity = "0.7";
    if(conflict) {
      this.timeConfictBannerTarget.classList.remove('hidden');
    }
  }

  enableButton() {
    this.submitBtnTarget.disabled = false;
    this.submitBtnTarget.classList.remove('disabled');
    this.submitBtnTarget.style.opacity = "1";
    this.timeConfictBannerTarget.classList.add('hidden');
  }

  selectAllDayTime() {
    this.fromTimeInputTarget.value = '00:00'
    this.toTimeInputTarget.value = '00:00'
  }

  selectAllDays() {
    this.dayInputTargets.forEach(target => {
      target.checked = true;
    });
    this.selectAllDaysButtonTarget.disabled = true;
    this.selectAllDaysButtonTarget.classList.add('opacity-60', 'cursor-not-allowed');
    this.checkTimeConflict();
  }

  enableSelectAllButton() {
    this.selectAllDaysButtonTarget.disabled = false;
    this.selectAllDaysButtonTarget.classList.remove('opacity-60', 'cursor-not-allowed');
  }

  mouseOver(event) {
    var timeslots = document.querySelectorAll("[data-schedules-id-param='" + event.target.dataset.schedulesIdParam + "']");
    let classes = ['hover', 'z-10'];
    
    if (timeslots[0] && timeslots[0].offsetWidth <= 130) {
      classes.push('popover-animation');
    }
    
    timeslots.forEach((el) => {
      el.classList.add(...classes);
    });
  }

  mouseOut(event) {
    var timeslots = document.querySelectorAll("[data-schedules-id-param='" + event.target.dataset.schedulesIdParam + "']");
    timeslots.forEach((el) => {
      el.classList.remove('hover', 'z-10');
    });
  }
}