import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["btn", "tab"]

  static values = {
    turboFrameId: String,
    setCustomEvent: Boolean 
  }

  change(event) {
    let selectedBtn = event.currentTarget;
    let selectedTab = this.tabTargets.find(element => element.dataset.targetId === selectedBtn.dataset.targetId);

    this.tabTargets.map(tab => tab.classList.add('hidden'));
    this.btnTargets.map(btn => btn.classList.remove('active', 'shadow'));

    selectedTab.classList.remove('hidden');
    selectedBtn.classList.add('active', 'shadow');

    if (this.setCustomEventValue) {
      // Custom event to trigger different controller events
      const trigger = new CustomEvent("tabChanged");
      window.dispatchEvent(trigger);
    }
  }

  selectChange(event) {
    let selectedOption = event.currentTarget.value;
    let selectedTab = this.tabTargets.find(element => element.dataset.targetId === selectedOption);

    this.tabTargets.map(tab => tab.classList.add('hidden'));

    selectedTab.classList.remove('hidden');

    // Custom event to trigger different controller events
    const trigger = new CustomEvent('tabChanged');
    window.dispatchEvent(trigger);
  }

  selectFrameChange(event) {
    const frame = document.getElementById(this.turboFrameIdValue);
    frame.src=event.target.value;
    frame.reload();
  }

  goToUrl(event){
    event.target.disabled = true;
    window.location = event.target.value;
  }
}
