import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["text", "readMoreButton", "truncatedText", "completeText"]

  toggleTruncate() {
    this.readMoreButtonTarget.classList.toggle("hidden");
    this.truncatedTextTarget.classList.toggle("hidden");
    this.completeTextTarget.classList.toggle("hidden");
  }
}