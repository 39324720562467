import moment from 'moment';
import { Controller } from "stimulus"
import TokenBuilder from '../src/javascripts/nowsignage/token-builder';
export default class extends Controller {

  // reinitialize datepicker and tag input after new html is loaded
  initialize() {
    this.loadComplementaryJs();
    this.addOrderedListItemsNumbers();
  }

  loadComplementaryJs() {
    $(".datetimepicker-always-utc").datetimepicker({
      format: "DD MMMM YYYY HH:mm",
      locale: moment.locale()
    }).on('dp.show dp.update', function () {
      $(".datepicker-years .picker-switch").removeAttr('title').on('click', function (e) { e.stopPropagation(); });
    });

    $('[data-toggle="tooltip"]').tooltip();

    TokenBuilder.init($("#playlist-form .tokenfield"));
  }

  addOrderedListItemsNumbers() { // add order numbers to loop items
    const listItems = document.getElementsByClassName("item-position");

    for (let i = 0; i < listItems.length; i++) {
      const li = listItems[i];
      li.innerHTML = i + 1;
    }
  }
}