import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    window.multiple_asset_ids = [];
    window.select_multiple_loop_item_ids = [];
  }

  handleSelectAllAssets() {
    var checkboxes = document.querySelectorAll('input[name=add_batch]');

    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = event.target.checked;
    }

    this.handleAddMultipleCheck();
  }

  handleAddMultipleCheck() {
    window.multiple_asset_ids = []; //reset value

    // fill array of selected assets
    var checked = document.querySelectorAll('input[name=add_batch]:checked');
    for (var i = 0; i < checked.length; i++) {
      window.multiple_asset_ids.push(checked[i].value);
    }

    // gray out not selected items
    var assetInfoContainers = document.querySelectorAll('.asset-info-container');
    for (var i = 0; i < assetInfoContainers.length; i++) {
      if (window.multiple_asset_ids.includes(assetInfoContainers[i].getAttribute('data-asset-id')) || checked.length == 0) {
        assetInfoContainers[i].classList.remove("opacity-50");
      } else {
        assetInfoContainers[i].classList.add("opacity-50");
      }
    }

    //update add buttons links for turbo
    const batchAddLinks = document.getElementsByClassName("add-batch-asset-btn");
    for (let i = 0; i < batchAddLinks.length; i++) {
      var url = new URL(batchAddLinks[i].href);
      url.searchParams.set('multiple_asset_ids', window.multiple_asset_ids);
      batchAddLinks[i].href = url;

    }

    // hide - show buttons
    if (checked.length == 0) {
      document.querySelectorAll(".add-asset-btn").forEach((el) => {
        el.classList.remove('hidden');
      });

      document.querySelectorAll(".add-batch-asset-btn").forEach((el) => {
        el.classList.add('hidden');
      });
    } else {
      document.querySelectorAll(".add-asset-btn").forEach((el) => {
        el.classList.add('hidden');
      });

      document.querySelectorAll(".add-batch-asset-btn").forEach((el) => {
        el.classList.remove('hidden');
      });
    }

  }

  handleSelectMultipleCheck() {
    window.select_multiple_loop_item_ids = []; //reset value

    const batchActionsLinkContainer = document.getElementById('batch-actions-loop-items-container');
    const batchReorderLink = document.getElementById('batch-reorder-loop-items-link');
    const batchDeleteLink = document.getElementById('batch-delete-loop-items-link');
    const batchEditLink = document.getElementById('batch-edit-loop-items-link');

    // fill array of selected loop_items
    var checked = document.querySelectorAll('input[name=delete_batch]:checked');
    for (var i = 0; i < checked.length; i++) {
      window.select_multiple_loop_item_ids.push(checked[i].value);
    }

    // gray out not selected items
    var loopItems = document.querySelectorAll('.loop-item-li');
    for (var i = 0; i < loopItems.length; i++) {
      if (window.select_multiple_loop_item_ids.includes(loopItems[i].getAttribute('data-item-id')) || window.select_multiple_loop_item_ids.length == 0) {
        loopItems[i].classList.remove("opacity-50");
      } else {
        loopItems[i].classList.add("opacity-50");
      }
    }

    // hide - show delete link
    if (window.select_multiple_loop_item_ids.length == 0) {
      batchActionsLinkContainer.classList.add('hidden');
      this.enableLoopItemsCtas();
    } else {
      batchActionsLinkContainer.classList.remove('hidden');
      this.disableLoopItemsCtas();
    }

    // update batch delete parameter
    var delete_url = new URL(batchDeleteLink.href);
    delete_url.searchParams.set('batch_loop_items_ids', window.select_multiple_loop_item_ids);
    batchDeleteLink.href = delete_url;

    // update batch edit parameter
    var edit_url = new URL(batchEditLink.href);
    edit_url.searchParams.set('batch_loop_items_ids', window.select_multiple_loop_item_ids);
    batchEditLink.href = edit_url;

    // update batch reorder parameter
    var reorder_url = new URL(batchReorderLink.href);
    reorder_url.searchParams.set('batch_loop_items_ids', window.select_multiple_loop_item_ids);
    batchReorderLink.href = reorder_url;
  }

  disableLoopItemsCtas() {
    var loopItemsCtas = document.querySelectorAll('.loop-item-cta');
    for (var i = 0; i < loopItemsCtas.length; i++) {
      loopItemsCtas[i].classList.add("hidden");
    }
  }

  enableLoopItemsCtas() {
    var loopItemsCtas = document.querySelectorAll('.loop-item-cta');
    for (var i = 0; i < loopItemsCtas.length; i++) {
      loopItemsCtas[i].classList.remove("hidden");
    }
  }
}