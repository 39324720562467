import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["submitButton"]
  static values = {
    key: String,
    name: String
  }

  initialize() {
    window.key_array = [];
    window.command_name = '';

    this.handleEdit();
  }

  resetKeyArray() {
    key_array[0] = 'watcher_event';
    key_array[1] = 'age_value';
  }

  handleEdit() {
    if (this.keyValue && this.nameValue) {
      key_array = this.keyValue.split('.');
      command_name = this.nameValue;

      this.showThresholdModifier();
      this.numberInputHandler();

      this.enableSubmitButton();
    } else {
      this.resetKeyArray();
    }
  }

  nameInput() {
    command_name = event.target.value;
    if (command_name === '') {
      this.hideThresholdModifier();
    } else {
      this.showThresholdModifier();
      this.resetKeyArray();
    }
  }

  showThresholdModifier() {
    document.getElementById('threshold-modifier').classList.remove("hidden");
    document.getElementById('threshold-modifier-select-msg').classList.remove("hidden");

    // on edit -> populate field
    if (key_array[2]) {
      document.getElementById('threshold-modifier').value = key_array[2];
    }
  }

  hideThresholdModifier() {
    this.hideKeySelectors();
    this.disableSubmitButton();

    document.getElementById('threshold-modifier').classList.add("hidden");
    document.getElementById('threshold-modifier-select-msg').classList.add("hidden");
  }

  modifierSelected() {
    const modifier_value = event.target.value

    if (modifier_value == '') {
      this.hideNumberInput();
    } else {
      key_array[2] = modifier_value;
      key_array.length = 3;// remove further values

      this.numberInputHandler()
    }
  }

  numberInputHandler() {
    if (key_array[2] === 'between') {
      this.hideNumberInput();
      document.getElementById('threshold-number-low-input').classList.remove('hidden');
      document.getElementById('threshold-number-high-input').classList.remove('hidden');
      document.getElementById('threshold-range-msg').classList.remove('hidden');

      // on edit -> populate fields
      if (key_array[3]) {
        document.getElementById('threshold-number-low-input').value = key_array[3].split('-')[0];
        document.getElementById('threshold-number-high-input').value = key_array[3].split('-')[1];
        this.showThresholdGender();
      }
    } else {
      this.hideNumberRangeInput();
      document.getElementById('threshold-number-input').classList.remove('hidden');
      document.getElementById('threshold-number-msg').classList.remove('hidden');

      // on edit -> populate field
      if (!isNaN(key_array[3]) && key_array[3]) {
        document.getElementById('threshold-number-input').value = key_array[3];
        this.showThresholdGender();
      }
    }
  }

  hideNumberInput() {
    document.getElementById('threshold-number-input').classList.add('hidden');
    document.getElementById('threshold-number-msg').classList.add('hidden');
    this.hideThresholdGender();
  }

  hideNumberRangeInput() {
    document.getElementById('threshold-number-low-input').classList.add('hidden');
    document.getElementById('threshold-number-high-input').classList.add('hidden');
    document.getElementById('threshold-range-msg').classList.add('hidden');
    this.hideThresholdGender();
  }

  numberSelected() {
    const number_value = event.target.value
    key_array[3] = number_value;
    key_array.length = 4;// remove further values

    this.showThresholdGender();
  }

  numberRangeSelected() {
    const valLow = document.getElementById('threshold-number-low-input').value;
    const valHigh = document.getElementById('threshold-number-high-input').value;
    let key = '';

    if (valLow < valHigh) {
      key = `${valLow}-${valHigh}`;
    } else {
      key = `${valHigh}-${valLow}`;
    }

    key_array[3] = key;

    this.showThresholdGender();
  }

  showThresholdGender() {
    document.getElementById('threshold-gender').classList.remove('hidden');
    document.getElementById('threshold-gender-msg').classList.remove('hidden');
    this.enableSubmitButton();

    // on edit -> populate field
    if (key_array[4]) {
      document.getElementById('threshold-gender').value = key_array[4];
    }
  }

  hideThresholdGender() {
    document.getElementById('threshold-gender').classList.add('hidden');
    document.getElementById('threshold-gender-msg').classList.add('hidden');
    this.disableSubmitButton();
  }

  genderSelected() {
    if (event.target.value !== '') {
      key_array[4] = event.target.value;
      key_array.length = 5;// remove further values
    } else {
      key_array.length = 4
    }
  }

  enableSubmitButton() {
    if (command_name != '') {
      this.submitButtonTarget.removeAttribute('disabled');
    }
  }
  disableSubmitButton() {
    this.submitButtonTarget.setAttribute('disabled', 'disabled');
  }

  submit() {
    var keys_array_form = document.getElementById('keys-array-form');
    keys_array_form.value = key_array.filter(e => e); //remove any empty strings

    var name_form = document.getElementById('name-form');
    name_form.value = command_name;

    var form = document.getElementById('trigger-command-form');
    form.submit();
  }
}
