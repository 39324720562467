var TokenBuilder = function($input) {
  this.$input = $input;
};

TokenBuilder.init = function($inputs) {
  $inputs.each(function() {
    new TokenBuilder($(this)).init();
  });
};

TokenBuilder.prototype.init = function() {
  this.$input.on(
    "tokenfield:createdtoken tokenfield:removedtoken",
    this.adjustPlaceholderVisibility.bind(this)
  );

  this.$input.tokenfield(this.getTokenFieldOptions());

  if(this.$input.data("save-on-change")) {
    this.$input.on("change", this.performSave.bind(this));
  }

  // Enforce tag create rules only after they have been populated initially
  if(this.getAutoCompleteData()) {
    this.$input.on(
      "tokenfield:createtoken tokenfield:edittoken",
      this.checkTagCreate.bind(this)
    );
  }
  if(this.getReadOnlyTags()) {
    this.$input.on(
      "tokenfield:removetoken tokenfield:edittoken",
      this.checkTagChange.bind(this)
    );

    this.$input.siblings(".token").each(function(i, element) {
      var tag = $(element).find(".token-label").text();

      if (this.getReadOnlyTags().includes(tag)) {
        $(element).addClass("disabled");
        $(element).tooltip({ title: "You don't have permission do delete this tag" });
      }
    }.bind(this));
  }

  // Don't submit form on ENTER key
  this.$input.parent().on("keydown", function(e) {
    var enterKeyCode = 13;

    if (e.keyCode == enterKeyCode) {
      return false;
    }
  });
};

TokenBuilder.prototype.getTokenFieldOptions = function() {
  var options = {};

  if(this.getAutoCompleteData()) {
    options.showAutocompleteOnFocus = true;
    options.autocomplete = {
      source: this.getAutoCompleteData(),
      open: function() {
        this.getAutoCompleteWrapper().addClass("token-list");
      }.bind(this)
    };
  }

  return options;
};

TokenBuilder.prototype.checkTagCreate = function(e) {
  var tag = e.attrs.value;
  var firstAutoCompleteTag = this.getAutoCompleteWrapper().find("li.ui-state-focus")
      .add(this.getAutoCompleteWrapper().find("li"))
      .first().text();

  var tags = this.$input.val().split(",").map(function(value) {
    return value.trim();
  });

  if(!this.getAutoCompleteData().includes(tag) && firstAutoCompleteTag.startsWith(tag)) {
    tag = firstAutoCompleteTag;

    e.attrs.value = e.attrs.label = tag;
  }

  return !tags.includes(tag) && this.getAutoCompleteData().includes(tag);
};

TokenBuilder.prototype.checkTagChange = function(e) {
  var tag = e.attrs.value;
  return !this.getReadOnlyTags().includes(tag);
};

TokenBuilder.prototype.getReadOnlyTags = function() {
  return this.$input.data("read-only-tags");
};

TokenBuilder.prototype.performSave = function() {
  var form = this.$input.parents("form");
  form.data("type", "json");
  $.rails.fire(form.get(0), "submit");
};

TokenBuilder.prototype.getAutoCompleteData = function() {
  return this.$input.data("source");
};

TokenBuilder.prototype.getAutoCompleteWrapper = function() {
  var $input = this.$input.data("bs.tokenfield").$input; // reload input data
  return $($input.data("ui-autocomplete").widget().context);
};

TokenBuilder.prototype.adjustPlaceholderVisibility = function() {
  var wrapper = this.$input.parent();
  var tokenCount = wrapper.children(".token").length;

  if (tokenCount > 0) {
    wrapper.addClass("hide-placeholder");
  } else {
    wrapper.removeClass("hide-placeholder");
  }
};

export default TokenBuilder;
