import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static targets = ["translateButton", "spinner", "confirmIcon", "textInput", "stringInput"]

  connect() {
    this.toggleConfirmIcons();
  }

  translate(event) {
    const url = "/super_admins/easy_translations"
    const activeLocaleTab = document.querySelector('[data-tabs-target="tab"]:not(.hidden)');
    const input = activeLocaleTab.querySelector("input");
    const textarea = activeLocaleTab.querySelector("textarea");
    const translateButton = event.target;

    translateButton.classList.add("hidden");
    this.spinnerTarget.classList.remove("hidden");

    const request = new FetchRequest("post", url, {
      body: JSON.stringify(
        {
          translations: {
            title: input.value,
            content: textarea.value
          }
        }
      ),
      responseKind: "json"
    });

    request.perform().then((result) => {
      return result.json;
    })
    .then(value => {
      Object.entries(value).forEach(([locale, translations]) => {
        if (translations) {
          this.confirmIconTargets.forEach(target => {
              if (target.dataset.locale == locale) {
                target.setAttribute("stroke", "#49be25");
              }
          });

          const stringInput = this.stringInputTargets.find(input => input.dataset.locale == locale);
          const textInput = this.textInputTargets.find(input => input.dataset.locale == locale);
          
          stringInput.value = translations.title;
          textInput.value = translations.content;
        }

        translateButton.classList.remove("hidden");
        this.spinnerTarget.classList.add("hidden");
      });
    });
  }

  toggleConfirmIcons(event = false) {
    if (event.target) {
      this.toggleSingleConfirmIcon(event.target);
    }
    else {
      this.stringInputTargets.forEach(input => {
        this.toggleSingleConfirmIcon(input);
      });
    }
  }

  toggleSingleConfirmIcon(input) {
    this.confirmIconTargets.forEach(target => {
      const color = input.value ? "#49be25" : "lightgray";

      if (input.dataset.locale == target.dataset.locale) {
        target.setAttribute("stroke", color);
      }
    });
  }
}