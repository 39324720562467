import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';

export default class extends Controller {
  static values = {
    chartData: Object,
    type: { type: String, default: 'pie' },
  }

  static targets = ['canvas']

  connect() {
    switch (this.typeValue) {
      case 'pie':
        this.pieChart();
      case 'bars':
        this.barsChart();
      case 'line':
        this.linesChart();
    }
  }

  linesChart() {
    const data = this.chartDataValue;

    new Chart(this.canvasTarget, {
      type: 'line',
      data: data,
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            grid: {
              display: false
            }
          }
        }
      }
    });
  }

  barsChart() {
    const data = this.chartDataValue;

    new Chart(this.canvasTarget, {
      type: 'bar',
      data: data,
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            grid: {
              display: false
            },
            beginAtZero: true
          }
        }
      }
    });
  }

  pieChart() {
    const data = this.chartDataValue;

    new Chart(this.canvasTarget, {
      type: 'pie',
      data: {
        labels: data.map(row => row.title),
        datasets: [
          {
            label: data[0].label,
            data: data.map(row => row.count),
            backgroundColor: data.map(row => row.color)
          }
        ]
      }
    });
  }
}
