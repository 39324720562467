import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "formAvailable",
    "pageInputAvailable",
    "formSelected",
    "pageInputSelected"
  ]

  connect() {
    console.log('SSC connect')
  } 

  pageSubmitAvailable(event){
    event.preventDefault();
    let pageNumber = event.currentTarget.getAttribute("href");
    if (pageNumber === "#") {} 
    else {
      pageNumber = pageNumber.split('page=')
      this.pageInputAvailableTarget.value = pageNumber.slice(-1);
      this.formAvailableTarget.requestSubmit()
    }
  }

  pageSubmitSelected(event){
    event.preventDefault();
    let pageNumber = event.currentTarget.getAttribute("href");
    if (pageNumber === "#") {} 
    else {
      pageNumber = pageNumber.split('page=')
      this.pageInputSelectedTarget.value = pageNumber.slice(-1);
      this.formSelectedTarget.requestSubmit()
    }
  }
}