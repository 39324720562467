import { Controller } from "stimulus"
import { FetchRequest } from "@rails/request.js"
import toastr from '../src/javascripts/nowsignage/toastr.min';

export default class extends Controller {
  static targets = ["startDateInput", "endDateInput"]
  static values = { loopId: Number, profileId: String }

  initialize() {
    window.timeout = null;

    toastr.options = {
      "closeButton": true,
      "progressBar": true,
      "positionClass": "toast-top-right",
      "timeOut": "3000",
    }
  }

  updateLoopName() {
    const name_value = event.target.value
    const loop_id = this.loopIdValue;
    const profile_cached_slug = this.profileIdValue;
    const url = `/profiles/${profile_cached_slug}/loops/${loop_id}`

    clearTimeout(window.timeout);

    window.timeout = setTimeout(function () {
      const request = new FetchRequest("patch", url, {
        body: JSON.stringify(
          {
            loop: {
              name: name_value
            }
          }
        ),
        responseKind: "json"
      })

      request.perform().then((result) => {
        if (result.response.ok) {
          return result.json;
        } else {
          return null;
        }
      }).then((value) => {
        toastr.success(value.message);
      });
    }, 700);
  }

  resetStartDate() {
    this.startDateInputTarget.value = '';
  }

  resetEndDate() {
    this.endDateInputTarget.value = '';
  }

}