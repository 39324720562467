import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    var results = document.getElementById('asset-search-result');
    if (typeof (results) == 'undefined' || results == null) {
      this.element.requestSubmit()
    }
  }

  search() {
    this.element.requestSubmit()
  }
}