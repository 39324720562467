import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['productFontSample', 'priceFontSample', 'descriptionFontSample', 'auxOneFontSample', 'auxTwoFontSample']
  static values = {
    placeholderFontSample: String
  }

  updateTextPlaceholders(event) {
    const text = event.target.value;

    this.productFontSampleTarget.innerText = text || this.placeholderFontSampleValue;
    this.priceFontSampleTarget.innerText = text || this.placeholderFontSampleValue;
    this.descriptionFontSampleTarget.innerText = text || this.placeholderFontSampleValue;
    this.auxOneFontSampleTarget.innerText = text || this.placeholderFontSampleValue;
    this.auxTwoFontSampleTarget.innerText = text || this.placeholderFontSampleValue;
  }

  resetTextPlaceholders() {
    const text = this.placeholderFontSampleValue;
    
    this.productFontSampleTarget.innerText = text;
    this.priceFontSampleTarget.innerText = text;
    this.descriptionFontSampleTarget.innerText = text;
    this.auxOneFontSampleTarget.innerText = text;
    this.auxTwoFontSampleTarget.innerText = text;
  }

  updateProductFont(event) {
    this.productFontSampleTarget.classList = '';
    this.productFontSampleTarget.classList.add("font-" + event.target.value);
  }

  updateProductColor(event) {
    this.productFontSampleTarget.style.color = event.target.value;
  }

  updatePriceFont(event) {
    this.priceFontSampleTarget.classList = '';
    this.priceFontSampleTarget.classList.add("font-" + event.target.value);
  }

  updatePriceColor(event) {
    this.priceFontSampleTarget.style.color = event.target.value;
  }

  updateDescriptionFont(event) {
    this.descriptionFontSampleTarget.classList = '';
    this.descriptionFontSampleTarget.classList.add("font-" + event.target.value);
  }

  updateDescriptionColor(event) {
    this.descriptionFontSampleTarget.style.color = event.target.value;
  }

  updateAuxOneFont(event) {
    this.auxOneFontSampleTarget.classList = '';
    this.auxOneFontSampleTarget.classList.add("font-" + event.target.value);
  }

  updateAuxOneColor(event) {
    this.auxOneFontSampleTarget.style.color = event.target.value;
  }

  updateAuxTwoFont(event) {
    this.auxTwoFontSampleTarget.classList = '';
    this.auxTwoFontSampleTarget.classList.add("font-" + event.target.value);
  }

  updateAuxTwoColor(event) {
    this.auxTwoFontSampleTarget.style.color = event.target.value;
  }
}