/**
 * Creates an instance of the NowSignage Dropzone
 * @param {String} profileId Profile ID string to request for
 * @param {Object} dropzone DropzoneJS instance object
 */
var NSDropzone = function (profileId) {
  // Types that can be accepted by the Dropzone uploader
  this.Types = { IMAGE: "image/*", VIDEO: "video/*" };

  // Ten minutes in miliseconds
  this.TEN_MINUTES_MS = (60 * 10) * 1000;

  /**
   * Request a presigned POST url to send file to AWS S3 and attaches
   * the response to the file object on the `presign` key
   * @param {Object} file A DropzoneJS File object
   * @param {Function} doneFunc Function to call once the file is approved to upload
   */
  this._requestPresignedUrl = function (dropzone, file, doneFunc) {
    $.ajax({
      url: format("/profiles/{0}/uploads/new", this.profileId),
      type: "GET",
      success: function (response) {
        file.presign = response;

        doneFunc();

        setTimeout(function () { dropzone.processFile(file); })
      },
      error: function () {
        doneFunc("Failed to reserve a URL on the storage medium.");
      }
    })
  };

  /**
   * Setup the FormData object with the required S3 credentials
   * @param {Object} file A DropzoneJS File object
   * @param {FormData} formData The FormData object to setup
   */
  this._presignedSetFormData = function (file, formData) {
    $.each(file.presign.fields, function (k, v) {
      formData.append(k, v);
    });
  };

  // Slug of the Profile that's uploading content
  this.profileId = profileId;

  // Validate the provided parameters
  if (!this.profileId) {
    throw new Error("No Profile ID provided to NSDropzone");
  }
};

/**
 * Creates a config to setup a DropzoneJS object
 * @param {Object} options pairs to map to the dropzoneJS object (see: DropzoneJS configuration)
 * @param {Function} onSuccessFunc A function to execute on the successful upload of a file
 */
NSDropzone.prototype.createConfig = function (options, onSuccessFunc) {
  var nsDropzone = this;

  var el = document.getElementById(options.id || "assetDropzone");

  el = (el === null ? "Click or drop files here to upload" : el.dataset.message);

  var defaultConfig = {
    url: "#",
    acceptedFiles: "image/*, video/*",
    dictDefaultMessage: el,
    maxFilesize: 5000,
    timeout: this.TEN_MINUTES_MS,
    parallelUploads: 1,
    uploadMultiple: false,
    autoProcessQueue: false,

    /**
     * Renames the File provided to prevent issues with URLs
     * @param {Object} file A DropzoneJS File object that has been selected
     */
    renameFile: function (file) {
      return file.name
        .replace(/[^A-z0-9\s\!\-\_\.\*\'\(\)]/g, "")
        .replace(/\s/g, "_")
        .replace(/\[/g, "")
        .replace(/\]/g, "");
    },

    accept: function (file, done) {
      nsDropzone._requestPresignedUrl(this, file, done);
    },

    sending: function (file, _xhr, formData) {
      nsDropzone._presignedSetFormData(file, formData);
    },

    processing: function (file) {
      if (file) {
        this.options.url = file.presign.url;
      }

      // TODO: Scope this to the parent dropzone object
      $(".dropzone .dz-message").css("display", "none");
    },

    error: function (file, response) {
      if ($.type(response) === "string") {
        file.previewElement.classList.add("dz-error");
        ref = file.previewElement.querySelectorAll("[data-dz-errormessage]");
        if (!ref) return;

        for (var i = 0; i < response.length; i++) {
          $(ref[0]).text(response[i]);
        }
      }
    },

    success: function (file, response) {
      if (typeof onSuccessFunc === 'function') {
        if (!file._successCalled) {
          file._successCalled = true;
          onSuccessFunc(nsDropzone, file, response, options.id);
        }
      }
    }
  };

  return Object.assign(defaultConfig, options);
};

/**
* Helper to parse the AWS S3 201 response from a presigned post upload
* @param {Object} response The XML response from AWS S3
* @returns A String containing the S3 key
*/
NSDropzone.parseResponseForKey = function (response) {
  if (response) {
    var xml = $.parseXML(response),
      $xmlResp = $($(xml).find("PostResponse"));

    return $xmlResp.find("Key").text();
  }

  return null;
};

export default NSDropzone;
