import { Controller } from "stimulus"
import interact from 'interactjs'

export default class extends Controller {

  connect() {
    const element = this.element
    
    this.zoneElement(element)
  }

  zoneElement(element) {
    const position = { x: 0, y: 0 }
    const controller = this;

    interact(`.${element.id}`).draggable({
      modifiers: [
        interact.modifiers.snap({
          targets: [
            interact.snappers.grid({ x: 5, y: 5 })
          ],
          range: Infinity,
          relativePoints: [ { x: 0, y: 0 } ]
        })
      ],
      listeners: {
        move (event) {
          // keep the dragged position in the data-x/data-y attributes
          position.x = (parseFloat(event.target.getAttribute('data-x')) || 0) + event.dx,
          position.y = (parseFloat(event.target.getAttribute('data-y')) || 0) + event.dy;

          event.target.style.transform =
            `translate(${position.x}px, ${position.y}px)`

          controller.updatePositionAttributes(event.target, element, position.x, position.y)
        },
      }
    }).resizable({
      margin: 5,
      edges: { top: true, left: true, bottom: true, right: true },
      listeners: {
        move: function (event) {
          let { x, y } = event.target.dataset

          x = (parseFloat(x) || 0) + event.deltaRect.left
          y = (parseFloat(y) || 0) + event.deltaRect.top

          Object.assign(event.target.style, {
            width: `${event.rect.width}px`,
            height: `${event.rect.height}px`,
            transform: `translate(${x}px, ${y}px)`
          })

          Object.assign(event.target.dataset, { x, y })

          controller.updateSizeAttributes(event.target, element)
          controller.updatePositionAttributes(event.target, element, x, y)
        }
      }
    })
  }

  updateSizeAttributes(target, element) {
    var relativeWidth = (target.offsetWidth / target.parentElement.offsetWidth * 100).toFixed(2)
    var relativeHeight = (target.offsetHeight / target.parentElement.offsetHeight * 100).toFixed(2)

    const zoneFields = document.getElementById(`fields-${element.id}`);
    var widthInput = zoneFields.querySelector('.width-input');
    var heightInput = zoneFields.querySelector('.height-input');

    widthInput.value = relativeWidth
    heightInput.value = relativeHeight
  }

  updatePositionAttributes(target, element, positionX, positionY) {
    const relativePosition = { left: 0, top: 0 }

    relativePosition.left = (parseFloat(target.style.left) + (positionX / target.parentElement.offsetWidth * 100)).toFixed(2)
    relativePosition.top = (parseFloat(target.style.top) + (positionY / target.parentElement.offsetHeight * 100)).toFixed(2)

    if(element.dataset.element != 'image'){
      if(relativePosition.left < 0) {
        relativePosition.left = 0
      }
      if(relativePosition.left > 100) {
        relativePosition.left = 100
      }
      if(relativePosition.top < 0) {
        relativePosition.top = 0
      }
      if(relativePosition.top > 100) {
        relativePosition.top = 100
      }
    }

    target.dataset.x = positionX
    target.dataset.y = positionY

    const zoneFields = document.getElementById(`fields-${element.id}`);
    var leftInput = zoneFields.querySelector('.left-input');
    var topInput = zoneFields.querySelector('.top-input');

    leftInput.value = relativePosition.left
    topInput.value = relativePosition.top
  }
}