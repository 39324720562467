import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["submitButton"]
  static values = {
    key: String,
    name: String
  }

  initialize() {
    window.key_array = [];
    window.command_name = '';

    this.handleEdit();
  }

  resetKeyArray() {
    key_array[0] = 'audience_current';
  }

  handleEdit() {
    if (this.keyValue && this.nameValue) {
      key_array = this.keyValue.split('.');
      command_name = this.nameValue;

      this.showScopeSelector();
      this.keySelectorHandler(key_array[1]);
      this.modifierSelectorHandler(key_array[2]);
      this.keySecondSelectorHandler(key_array[3]);
      this.numberInputHandler(key_array[4]);

      this.enableSubmitButton();
    } else {
      this.resetKeyArray();
    }
  }

  nameInput() {
    command_name = event.target.value;
    if (command_name === '') {
      this.hideScopeSelector();
    } else {
      this.resetKeyArray();
      this.showScopeSelector()
    }
  }

  showScopeSelector() {
    document.getElementById('scope-select').classList.remove("hidden");
    document.getElementById('scope-select-msg').classList.remove("hidden");

    // on edit -> populate field
    if (key_array[1]) {
      document.getElementById('scope-select').value = key_array[1]
    }
  }

  hideScopeSelector() {
    this.hideKeySelectors();
    this.disableSubmitButton();

    document.getElementById('scope-select').classList.add("hidden");
    document.getElementById('scope-select-msg').classList.add("hidden");
  }

  scopeSelected() {
    const scope_value = event.target.value

    if (scope_value == '') {
      this.hideKeySelectors();
    } else {
      key_array[1] = scope_value;
      key_array.length = 2;// remove further values
      this.keySelectorHandler(scope_value);
    }
  }

  keySelectorHandler(scope_selected) {
    this.hideKeySelectors();

    //show the Key selector
    var key_selector_show = 'scope-' + scope_selected;
    var key_selector_visible = document.getElementById(key_selector_show);
    key_selector_visible.classList.remove("hidden");
    document.getElementById('key-select-msg').classList.remove("hidden");

    // on edit -> populate field
    if (key_array[2]) {
      key_selector_visible.value = key_array[2]
    }
  }

  hideKeySelectors() {
    this.hideModifierSelectors();
    this.disableSubmitButton();

    var key_selectors = document.getElementsByClassName("key-selector");
    for (var i = 0; i < key_selectors.length; i++) {
      key_selectors[i].classList.add("hidden");
    }
    document.getElementById('key-select-msg').classList.add("hidden");
  }

  keySelected() {
    const key_value = event.target.value

    if (key_value == '') {
      this.hideModifierSelectors();
    } else {
      key_array[2] = key_value;
      key_array.length = 3; // remove further values
      this.modifierSelectorHandler(key_value);
    }
  }

  modifierSelectorHandler(key_selected) {
    this.hideModifierSelectors();

    var modifier_selector_show = '';
    if (key_selected == 'total') {
      modifier_selector_show = 'modifier-total';
    } else {
      modifier_selector_show = 'modifier-gender-age';
    }

    var modifier_selector_visible = document.getElementById(modifier_selector_show);
    modifier_selector_visible.classList.remove("hidden");
    document.getElementById('modifier-select-msg').classList.remove("hidden");

    // on edit -> populate field
    if (key_array[3]) {
      modifier_selector_visible.value = key_array[3];
    }
  }

  hideModifierSelectors() {
    this.hideKeySecondSelectors();
    this.disableSubmitButton();

    var key_selectors = document.getElementsByClassName("modifier-selector");
    for (var i = 0; i < key_selectors.length; i++) {
      key_selectors[i].classList.add("hidden");
    }
    document.getElementById('modifier-select-msg').classList.add("hidden");
  }

  modifierSelected() {
    const modifier_value = event.target.value

    if (modifier_value == '') {
      this.hideKeySecondSelectors();
    } else {
      key_array[3] = modifier_value;
      key_array.length = 4;// remove further values
      this.keySecondSelectorHandler(modifier_value);
    }
  }

  keySecondSelectorHandler(modifier_selected) {
    this.hideKeySecondSelectors();

    if (modifier_selected == 'majority') {
      this.enableSubmitButton();
      return;
    }

    var key_second_selector = '';
    var scope = key_array[1];
    var key = key_array[2];
    switch (scope) {
      case 'gender':
        key_second_selector = document.getElementById('key-second-gender');
        break;
      case 'age':
        key_second_selector = document.getElementById('key-second-age');
        break;
      case 'total':
        if (['male', 'female'].includes(key)) {
          key_second_selector = document.getElementById('key-second-gender');
        } else if (['child', 'young', 'adult', 'senior'].includes(key)) {
          key_second_selector = document.getElementById('key-second-age');
        } else {
          key_second_selector = document.getElementById('key-second-total');
        }

        break;
    }

    key_second_selector.classList.remove("hidden");
    document.getElementById('key-second-select-msg').classList.remove("hidden");

    // on edit -> populate field
    if (key_array[4]) {
      key_second_selector.value = key_array[4];
      if (!isNaN(key_array[4])) {
        key_second_selector.value = 'number';
      }
    }

    //remove first key selected option
    for (var i = 0; i < key_second_selector.options.length; i++) {
      key_second_selector.options[i].classList.remove('hidden');
      if (key_second_selector.options[i].value == key) {
        key_second_selector.options[i].classList.add('hidden');
      }
    }
  }

  hideKeySecondSelectors() {
    this.hideNumberInput();
    this.disableSubmitButton();

    var key_selectors = document.getElementsByClassName("key-second-selector");
    for (var i = 0; i < key_selectors.length; i++) {
      key_selectors[i].classList.add("hidden");
    }
    document.getElementById('key-second-select-msg').classList.add("hidden");
  }

  keySecondSelected() {
    const key_second_value = event.target.value

    if (key_second_value == '') {
      this.hideNumberInput();
    } else {
      key_array[4] = key_second_value;
      key_array.length = 5;// remove further values
      this.numberInputHandler(key_second_value);
    }
  }

  numberInputHandler(key_second_value) {
    if (key_second_value == 'number' || !isNaN(key_second_value)) {
      var number_input = document.getElementById('number-input');
      number_input.classList.remove('hidden');
    } else {
      this.disableSubmitButton();
    }

    // on edit -> populate field
    if (!isNaN(key_array[4]) && key_array[4]) {
      document.getElementById('number-input').value = key_array[4];
    }

    this.enableSubmitButton();
  }

  hideNumberInput() {
    var number_input = document.getElementById('number-input');
    number_input.classList.add('hidden');
    this.disableSubmitButton();
  }

  numberSelected() {
    const number_value = event.target.value
    key_array[4] = number_value;
    key_array.length = 5;// remove further values
  }

  enableSubmitButton() {
    if (command_name != '') {
      this.submitButtonTarget.removeAttribute('disabled');
    }
  }
  disableSubmitButton() {
    this.submitButtonTarget.setAttribute('disabled', 'disabled');
  }

  submit() {
    var keys_array_form = document.getElementById('keys-array-form');
    keys_array_form.value = key_array;

    var name_form = document.getElementById('name-form');
    name_form.value = command_name;

    var form = document.getElementById('trigger-command-form');
    form.submit();
  }
}
