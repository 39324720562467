import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['textInput', 'hiddenInput', 'screenIdsInput', 'form', 'screenId']
  static values = { open: Boolean }

  screensSearch() {
    this.hiddenInputTarget.value = this.textInputTarget.value;

    this.screenIdsInputTargets.forEach(target => {
      target.remove();
    });

    if(this.hasScreenIdTarget) {
      this.screenIdTargets.forEach(target => {
        var hiddenInput = document.querySelector('[data-selected-screen-id="' + target.value + '"]');

        if(hiddenInput){
          return;
        }

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "search[selected_screen_ids][]";
        input.value = target.value;
        input.dataset.triggerGroupsTarget = "screenIdsInput";
        input.dataset.selectedScreenId = target.value;
        input.multiple = "multiple";

        this.formTarget.append(input);
      });
    }

    this.formTarget.requestSubmit();
  }
}
