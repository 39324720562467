/* eslint no-console:0 */

import 'src/javascripts/devise';
import 'src/stylesheets/devise';

// import Rails from "@rails/ujs"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"
// Rails.start()
// ActiveStorage.start()

import "@hotwired/turbo-rails"
import "../controllers"
