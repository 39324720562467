import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { 
    milisecsToHide: { type: Number, default: 5000 },
    autoclose: { type: Boolean, default: true },
    closeOnClickAnywhere: { type: Boolean, default: true},
  }

  connect() {
    this.appear()
    
    if (this.autocloseValue) {
      this.setupAutoCloseTimer();
    }

    if (this.closeOnClickAnywhereValue) {
      document.addEventListener('click', this.handleDocumentClick.bind(this));
      this.element.addEventListener('mouseover', this.handleMouseOver.bind(this));
      this.element.addEventListener('mouseout', this.handleMouseOut.bind(this));
    }
  }

  disconnect() {
    if (this.closeOnClickAnywhereValue) {
      document.removeEventListener('click', this.handleDocumentClick.bind(this));
    }
  }

  appear() {
    this.element.classList.add('transition', 'duration-500', 'ease-in-out', 'translate-x-full', 'opacity-0');
    requestAnimationFrame(() => {
      this.element.classList.remove('opacity-0', 'translate-x-full');
    });
  }

  dismiss() {
    this.element.classList.add('translate-x-full', 'opacity-0');
    this.element.addEventListener('transitionend', () => {
      this.element.remove();
    });
  }

  setupAutoCloseTimer(miliseconds = this.milisecsToHideValue) {
    this.autoCloseTimer = setTimeout(() => {
      this.dismiss();
    }, miliseconds);
  }

  handleMouseOver() {
    clearTimeout(this.autoCloseTimer);
  }

  handleMouseOut() {
    this.setupAutoCloseTimer(1000);
  }

  handleDocumentClick(event) {
    if (!this.element.contains(event.target)) {
      this.dismiss();
    }
  }
}
