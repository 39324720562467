import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['ratioInput', 'layoutsContainer', 'topInput', 'leftInput', 'widthInput', 'heightInput']
  static values = { sectionId: String }

  updateAspectRatio(event) {
    const parentClasses = event.currentTarget.dataset.parentValue.split(" ");
    this.layoutsContainerTarget.classList.remove('sixteen-nine', 'nine-sixteen', 'four-three', 'three-four', 'one-one');
    this.layoutsContainerTarget.classList.add(event.currentTarget.dataset.ratioValue);
    this.layoutsContainerTarget.parentNode.setAttribute('class', '');
    
    parentClasses.forEach(c => {
      this.layoutsContainerTarget.parentNode.classList.add(c);
    });
  }

  updateSection(e) {
    if (this.checkInputValue(event.currentTarget.value)) {
      const section = document.getElementById(this.sectionIdValue);

      section.style.width = this.widthInputTarget.value + '%';
      section.style.height = this.heightInputTarget.value + '%';
      section.style.left = this.leftInputTarget.value + '%';
      section.style.top = this.topInputTarget.value + '%';
    } else {
      event.currentTarget.value = event.currentTarget.defaultValue;
    }
  }

  checkInputValue(value) {
    return (value <= 100 && value >=0)
  }
}
