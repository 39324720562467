import { Controller } from "stimulus"
import Sortable from "sortablejs"
import { FetchRequest } from "@rails/request.js"
import toastr from '../src/javascripts/nowsignage/toastr.min';

// Reusable component for sorting items
// Add data-controller: 'sortable' to the parent of sortable items
// Add data-url with the update url (with item id) to the item, used to PATCH object and update position
export default class extends Controller {

  static values = { turboResponse: Boolean }

  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    })
  }

  initialize() {
    toastr.options = {
      "closeButton": true,
      "progressBar": true,
      "positionClass": "toast-top-right",
      "timeOut": "3000",
    }
  }

  end(event) {
    const url = event.item.dataset.url
    this.updatePosition(url, event.newIndex)
  }

  addOrderedListItemsNumbers() { // add order numbers to loop items
    const listItems = document.getElementsByClassName("item-position");

    for (let i = 0; i < listItems.length; i++) {
      const li = listItems[i];
      li.innerHTML = i + 1;
    }
  }

  async updatePosition(url, position) {
    const request = new FetchRequest("patch", url, {
      body: {
        position: position
      },
      responseKind: this.turboResponseValue ? "turbo-stream" : "json"
    });

    const response = await request.perform()

    if (response.ok) {
      if (!this.turboResponseValue) {
        const body = await response.text;
        const json = JSON.parse(body);
        toastr.success(json.message);
      }
        
      this.addOrderedListItemsNumbers();
    }
  }
}
