import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "pageInput" ]

  connect() {    
  } 

  submitForm(event){
    event.preventDefault();
    let pageNumber = event.target.getAttribute("href");
    if (pageNumber === "#") {} 
    else {
      pageNumber = pageNumber.split('page=')
      this.pageInputTarget.value = pageNumber.slice(-1);
      this.formTarget.requestSubmit()
    }
  }
}