import { Controller } from "stimulus"

export default class extends Controller {

  static values = {
    objectsIds: Array,
    selectAll: Boolean
  }

  static targets = [  'selectedCount',
                      'selectedCountModal',
                      'allSelectedCountModal',
                      'applyButton',
                      'removeButton',
                      'applyTagsForm',
                      'removeTagsForm',
                      'applyTagsObjectIdsField',
                      'removeTagsObjectIdsField',
                      'selectedTagsIds',
                      'applyTagIdsField',
                      'removeTagIdsField',
                      'submitAddTaggings',
                      'submitRemoveTaggings' ]

  initialize() {
    if (this.hasApplyButtonTarget) {
      this.enableOrDisableButtons();
    }
  }

  checkbox(){
    const id = event.target.value;
    let ids = this.applyTagsObjectIdsFieldTarget.value.split(' ');
    ids = ids.filter(item => item); // remove empty strings

    if (event.target.checked) {

      if (!ids.includes(id)){
        ids.push(id);
      }
      
      if (this.hasSelectedCountTarget) {
        this.increaseCounter();
      }
      
    } else {
      var index = ids.indexOf(id);
      if (index !== -1) {
        ids.splice(index, 1);
      }

      if (this.hasSelectedCountTarget) {
        this.decreaseCounter();
      }
    }

    this.applyTagsObjectIdsFieldTarget.value = ids.join(' ');
    this.removeTagsObjectIdsFieldTarget.value = ids.join(' ');

    if (this.hasApplyButtonTarget) {
      this.enableOrDisableButtons();
    }
  }

  addTagsModal(){
    this.submitAddTaggingsTarget.classList.remove('hidden');
    this.submitRemoveTaggingsTarget.classList.add('hidden');

    if (this.selectAllValue){
      this.allSelectedCountModalTarget.classList.remove('hidden');
      this.selectedCountModalTarget.classList.add('hidden');
    } else {
      this.allSelectedCountModalTarget.classList.add('hidden');
      this.selectedCountModalTarget.classList.remove('hidden');
    }
  }

  removeTagsModal(){
    this.submitAddTaggingsTarget.classList.add('hidden');
    this.submitRemoveTaggingsTarget.classList.remove('hidden');

    if (this.selectAllValue){
      this.allSelectedCountModalTarget.classList.remove('hidden');
      this.selectedCountModalTarget.classList.add('hidden');
    } else {
      this.allSelectedCountModalTarget.classList.add('hidden');
      this.selectedCountModalTarget.classList.remove('hidden');
    }
  }

  enableOrDisableButtons() {
    let ids = this.applyTagsObjectIdsFieldTarget.value.split(' ');
    ids = ids.filter(item => item); // remove empty strings

    if (ids.length === 0 && this.selectAllValue != true) {
      this.applyButtonTarget.setAttribute("data-action", "");
      this.removeButtonTarget.setAttribute("data-action", "");
      this.applyButtonTarget.style.opacity = 0.7;
      this.removeButtonTarget.style.opacity = 0.7;
      this.applyButtonTarget.classList.add('cursor-not-allowed');
      this.removeButtonTarget.classList.add('cursor-not-allowed');
    } else {
      this.applyButtonTarget.setAttribute("data-action", "click->modal#open click->tag-manager#addTagsModal")
      this.removeButtonTarget.setAttribute("data-action", "click->modal#open click->tag-manager#removeTagsModal")
      this.applyButtonTarget.style.opacity = 1;
      this.removeButtonTarget.style.opacity = 1;
      this.applyButtonTarget.classList.remove('cursor-not-allowed');
      this.removeButtonTarget.classList.remove('cursor-not-allowed');
    }
  }

  increaseCounter() {
    this.selectedCountTarget.textContent = parseInt(this.selectedCountTarget.textContent) + 1
    this.selectedCountModalTarget.textContent = parseInt(this.selectedCountModalTarget.textContent) + 1
  }

  decreaseCounter() {
    this.selectedCountTarget.textContent = parseInt(this.selectedCountTarget.textContent) - 1
    this.selectedCountModalTarget.textContent = parseInt(this.selectedCountModalTarget.textContent) - 1
  }

  submitAddTags() {
    // console.log('this.selectedTagsIdsTarget.value:', this.selectedTagsIdsTarget.value);

    this.applyTagIdsFieldTarget.value = this.selectedTagsIdsTarget.value;
    this.applyTagsFormTarget.requestSubmit();
  }

  submitRemoveTags() {
    // console.log('this.selectedTagsIdsTarget.value:', this.selectedTagsIdsTarget.value);

    this.removeTagIdsFieldTarget.value = this.selectedTagsIdsTarget.value;
    this.removeTagsFormTarget.requestSubmit();
  }
}
