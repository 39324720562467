import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["previewContainer", "ratioContainer", "iframeContainer", "ratio"]

  static values = {
    iframeSrc: String
  };

  show() {
    let iframe = document.createElement("iframe");

    iframe.id = "preview-screen-iframe";
    iframe.src = this.iframeSrcValue;
    iframe.height = "100%";
    iframe.width = "100%";

    this.ratioTarget.classList.remove('opacity-70');
    this.ratioTarget.classList.add('opacity-40');

    this.iframeContainerTarget.innerHTML = '';
    this.iframeContainerTarget.appendChild(iframe);
  }

  aspectRatio(event) {
    event.currentTarget.parentElement.querySelectorAll("li.bg-white.shadow")[0].classList.remove('bg-white', 'shadow');
    event.currentTarget.classList.add('bg-white', 'shadow');

    this.previewContainerTarget.classList.remove('w-full', 'w-3/5', 'w-2/3', 'w-4/5');
    this.previewContainerTarget.classList.add(event.currentTarget.dataset.parentValue.split(" ")[0]);

    this.ratioContainerTarget.classList.remove('sixteen-nine', 'nine-sixteen', 'four-three', 'three-four', 'one-one');
    this.ratioContainerTarget.classList.add(event.currentTarget.dataset.ratioValue);
  }
}
