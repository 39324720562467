import { Controller } from "stimulus"

export default class extends Controller {
  // Code migrated from: https://github.com/nirsky/react-native-size-matters/blob/master/lib/scaling-utils.js#L12C1-L12C91
  static values = { 
    size: Number,
    relativeContainerClass: String //the class that identifies the main container of the element, from where we need to calculate the relative size
  }

  initialize() {
    this.element.style.fontSize = this.dynamicScale();
  }

  trigger() {
    this.element.style.fontSize = this.dynamicScale();
  }

  dynamicScale() {
    const scaledSize = this.scale(this.sizeValue);
    return `${scaledSize}px`;
  }

  relativeContainerWidthHeight() {
    const relativeContainer = this.element.closest(`.${this.relativeContainerClassValue}`);

    return {
      width: relativeContainer.clientWidth,
      height: relativeContainer.clientHeight
    };
  }

  moderateScale(size, factor = 0.5) {
    return size + (this.scale(size) - size) * factor;
  }

  scale(size){
    const guidelineBaseWidth = 350;
    const { width, height } = this.relativeContainerWidthHeight();
    const [shortDimension, longDimension] = width < height ? [width, height] : [height, width];
    
    return (shortDimension / guidelineBaseWidth * size);
  }
}
