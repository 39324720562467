import moment from 'moment';
import { Controller } from "stimulus"
export default class extends Controller {

  // reinitialize datepicker after new html is loaded with turbo
  initialize() {
    this.loadComplementaryJs();
  }

  loadComplementaryJs() {
    $(".timepicker").datetimepicker({
      format: "HH:mm",
      locale: moment.locale()
    }).on('dp.show dp.update', function () {
      $(".datepicker-years .picker-switch").removeAttr('title').on('click', function (e) { e.stopPropagation(); });
    });

    $(".datetimepicker.onlydate").datetimepicker({
      format: "DD-MM-YYYY",
      locale: moment.locale()
    }).on('dp.show dp.update', function () {
      $(".datepicker-years .picker-switch").removeAttr('title').on('click', function (e) { e.stopPropagation(); });
    });

    $('[data-toggle="tooltip"]').tooltip();
  }
}