import { Controller } from "stimulus"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static values = { view: String };
  static targets = ["gridButton", "listButton", "gridSvg", "listSvg"];

  initialize() {
    if (this.viewValue == "list") {
      this.updateListClasses();
    } else {
      this.updateGridClasses();
    }
  }

  activateGridView(event) {
    this.updateCustomerSetting("grid");
    this.updateGridClasses();
  }

  activateListView(event) {
    this.updateCustomerSetting("list");
    this.updateListClasses();
  }

  updateGridClasses() {
    this.gridButtonTarget.classList.add("bg-white", "shadow");
    this.listButtonTarget.classList.remove("bg-white", "shadow");
    this.gridSvgTarget.classList.add("text-blue-500");
    this.listSvgTarget.classList.remove("text-blue-500");
  }

  updateListClasses() {
    this.listButtonTarget.classList.add("bg-white", "shadow");
    this.gridButtonTarget.classList.remove("bg-white", "shadow");
    this.listSvgTarget.classList.add("text-blue-500");
    this.gridSvgTarget.classList.remove("text-blue-500");
  }

  async updateCustomerSetting(viewFormat) {
    const request = new FetchRequest("patch", "/customer", {
      body: {
        customer: {
          view_format: viewFormat
        }
      },
      responseKind: "json"
    })
    const response = await request.perform()
  }
}
