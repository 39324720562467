import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["fullScreenDiv", "enterFullScreenButton", "exitFullScreenButton"];

  static values = {
    closeMessage: { type: String, default: "Press ESC to exit full screen" }
  }

  connect() {
    document.addEventListener("keydown", this.handleKeyDown.bind(this));
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeyDown.bind(this));
  }

  handleKeyDown(event) {
    if (event.key === "Escape") {
      this.exitFullScreen();
    }
  }

  fullScreen() {
    if (this.hasEnterFullScreenButtonTarget){
      this.enterFullScreenButtonTarget.classList.add('hidden');
    }
    
    this.exitFullScreenButtonTarget.classList.remove("hidden");
    this.fullScreenDivTarget.classList.add("fixed", "top-0", "bottom-0", "start-0", "end-0", "bg-white", "z-50", "p-12", "overflow-y-auto") 
    this.exitFullScreenButtonTarget.classList.remove("hidden");

    const messageDiv = document.createElement("div");

    messageDiv.setAttribute("id", "message");
    messageDiv.classList.add("mx-auto", "-mt-8", "w-max", "rounded-lg", "text-2xl", "px-8", "py-6", "bg-black", "bg-opacity-50", 
                            "absolute", "start-0", "end-0", "text-white", "transition-all", "duration-500");
    messageDiv.textContent = this.closeMessageValue;
    
    this.fullScreenDivTarget.prepend(messageDiv);

    setTimeout(() => {
      messageDiv.classList.add("opacity-0");

      // Wait for the transition to finish before deleting the message.
      setTimeout(() => {
        messageDiv.remove();
      }, 500); 
      
    }, 2000);
  }

  exitFullScreen() {
    this.fullScreenDivTarget.classList.remove("fixed", "top-0", "bottom-0", "start-0", "end-0", "bg-white", "z-50", "p-12", "overflow-y-auto") 
    this.exitFullScreenButtonTarget.classList.add("hidden")

    if (this.hasEnterFullScreenButtonTarget){
      this.enterFullScreenButtonTarget.classList.remove('hidden');
    }

    const messageDiv = document.getElementById("message");

    if (messageDiv) {
      messageDiv.remove();
    }
  }
}
