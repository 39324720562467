import Dropzone from 'dropzone/dist/dropzone';
import NSDropzone from './dropzone';
import { FetchRequest } from "@rails/request.js";

Dropzone.autoDiscover = false;

window.Assets = {
  Types: { IMAGE: "image/*", VIDEO: "video/*" },

  TEN_MINUTES_MS: (60 * 10) * 1000,

  newUiInitializeDropzone: function(profileId, dropzoneId) {
    console.log('newUiAssetDropzone');
    new Dropzone(`#${dropzoneId}`, new NSDropzone(profileId).createConfig({
      acceptedFiles: "image/*, video/*, text/csv, application/csv",
      maxFilesize: 5000,
      parallelUploads: 1,
      uploadMultiple: false,
      id: dropzoneId,
    }, Assets.newUiNewAssetUploaded));
    
  },

  newUiInitializeEditDropzone: function(profileId) {
    new Dropzone("#newUiEditAssetDropzone", new NSDropzone(profileId).createConfig({
      acceptedFiles: "image/*, video/*",
      maxFilesize: 5000,
      parallelUploads: 1,
      uploadMultiple: false,
      id: "newUiEditAssetDropzone",
    }, Assets.onAssetEdited));
    
  },

  initializeDropzone: function(profileId) {
    var newDropzone = new NSDropzone(profileId);
    Dropzone.options.assetDropzone = newDropzone.createConfig({
      acceptedFiles: "image/*, video/*",
      maxFilesize: 5000,
      parallelUploads: 1,
      uploadMultiple: false,
    }, Assets.onNewAssetUploaded);
  },

  initializeEditDropzone: function(profileId) {
    new Dropzone("#editDropzone", new NSDropzone(profileId).createConfig({
      acceptedFiles: "image/*, video/*",
      maxFilesize: 5000,
      parallelUploads: 1,
      uploadMultiple: false,
      maxFiles: 1,
    }, Assets.onAssetEdited));
  },

  /**
   * Perform the record creation for the upcoming Content file
   * @param {Object} file A DropzoneJS File object
   * @param {XmlDocument} response The HTTP 201 response from S3
   */
  onNewAssetUploaded: function(nsDropzone, file, response) {
    var video_codec_radio_buttons = "input[type='radio'][data-codec]";

    var xml = $.parseXML(response),
      $xmlResp = $($(xml).find("PostResponse")),
      key = $xmlResp.find("Key").text(),
      name = file.upload.filename,
      type = file.type;

    var tags = [];
    if ($('.tag-choices').length != 0) {
      tags = Array.prototype.slice.call(document.querySelectorAll('.tag-choices option:checked'),0).map(function(v,i,a) { return v.value; }).join(",");
    } else if ($('#image_access_tag_list').length != 0) {
      tags = $('#image_access_tag_list').tokenfield('getTokens').map(x => x.value).join(",");
    }

    let imageRotate = null; 
    if (document.getElementById("image_rotate")) {
      imageRotate = document.getElementById("image_rotate").value;
    }

    $.ajax({
      url: format("/profiles/{0}/assets", nsDropzone.profileId),
      type: "POST",
      data: {
        asset: {
          access_tag_list: tags,
          key: key,
          name: name,
          type: type,
          codec: $(video_codec_radio_buttons+":checked").val(),
          rotate: imageRotate
        }
      },
      success: function () {
        var route = format("/profiles/{0}/assets/list.js", nsDropzone.profileId);

        // Reload the updated asset list from the server:
        $.get(route, function() {
          $(file.previewElement).remove();
          if($("#image-dropzone .dz-preview").length == 0) {
            $(".dropzone .dz-message").css("display", "block");
          }
        });
      },
      error: function () { }
    });
  },

  newUiNewAssetUploaded: async function(nsDropzone, file, response, dropzoneId) {
    var video_codec_radio_buttons = "input[type='radio'][data-codec]";

    var xml = $.parseXML(response),
      $xmlResp = $($(xml).find("PostResponse")),
      key = $xmlResp.find("Key").text(),
      name = file.upload.filename,
      type = file.type;

    var zone = document.getElementById(dropzoneId);
    var tags = [];
    var tag_input = document.getElementById("image_access_tag_list");
    if (tag_input) {
      document.querySelectorAll('#image_access_tag_list option').forEach(option => {
        tags.push(option.value)
      });
      tags = tags.join(',')
    }
    var referer = 'assets';
    var folderId = null;
    var form = document.getElementById("dropzone-form");
    if(form){
      folderId = form.dataset.folderId;
      referer = form.dataset.referer;
    }

    let imageRotate = null; 
    if (document.getElementById("image_rotate")) {
      imageRotate = document.getElementById("image_rotate").value;
    }

    const request = new FetchRequest("post", format("/profiles/{0}/assets", nsDropzone.profileId), {
      body: JSON.stringify(
        {
          asset: {
            access_tag_list: tags,
            key: key,
            name: name,
            type: type,
            codec: $(video_codec_radio_buttons+":checked").val(),
            assets_folder_id: folderId,
            rotate: imageRotate,
            referer: referer
          }
        }
      ),
      responseKind: "turbo-stream"
    });

    const request_response = await request.perform();

    if (request_response.ok) {
      $(file.previewElement).remove();

      if($("#image-dropzone .dz-preview").length == 0) {
        if (zone.querySelector(".dz-message").innerText == "undefined") {
          zone.style.visibility = "hidden";
          $("#assets-dropzone-actions").css("visibility", "hidden");
        }
        else {
          zone.style.visibility = 'visible';
          $(".dz-message").css("display", "block");
        }
      }
    }
  },

  /**
   * Performs the record updating for the incoming Upload file
   * @param {Object} nsDropzone A DropzoneJS File object
   * @param {Object} file A DropzoneJS File object
   * @param {XmlDocument} response The HTTP 201 response from S3
   * TODO: Replace the XML parsing with NSDropzone.parseResponseForKey
   */
  onAssetEdited: function(_nsDropzone, file, response) {
    var xml = $.parseXML(response),
        $xmlResp = $($(xml).find("PostResponse")),
        key = $xmlResp.find("Key").text(),
        name = file.upload.filename;

    if ( $("#presign_image_uid").length > 0 ) {
      $("#presign_image_uid").val(key);
      $("#presign_image_name").val(name);
    } else {
      $("#presign_video_uid").val(key);
      $("#presign_video_name").val(name);
    }
  },

  bindForms: function() {
    var video_codec_radio_buttons = "input[type='radio'][data-codec]";
    var video_codec_form = "form[data-codec]";
    var url = $(video_codec_form).attr("action");

    $(video_codec_radio_buttons).on("change", function() {
      $.post(url, {video_codec: $(this).val()},
        function(data, status) {
          location.reload();
        }
      );
    });
  }
};


document.addEventListener("DOMContentLoaded",function() {
  const profileId = $("body").data("profileId");
  const getOnboardingConfig = () => {
    return new NSDropzone(profileId).createConfig({
      acceptedFiles: "image/*, video/*",
      maxFilesize: 5000,
      parallelUploads: 1,
      uploadMultiple: false,
    }, Assets.onNewAssetUploaded)
  };
  if($("[data-dropzone='onboarding']")) {
    $("[data-dropzone='onboarding']").dropzone(getOnboardingConfig());  
  };
});
