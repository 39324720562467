import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    moveUrl: String,
    folderId: Number,
    modelName: String
  };
  static targets = [ "checkbox", "moveButton", "actionsContainer" ]

  initialize() {
    window.itemIds = [];
    window.folderIds = [];

    if (this.hasMoveButtonTarget) {
      this.populateIds();  
    } 
  }

  selectAll() {
    if (event.target.checked) {
      this.checkboxTargets.forEach(target => {
        if (!target.checked) {
          target.checked = true;
          if (target.dataset.itemType == 'folder') {
            window.folderIds.push(parseInt(target.value))
          } else {
            window.itemIds.push(parseInt(target.value))
          }
        }
      });
    } else {
      this.checkboxTargets.forEach(target => {
        if (target.checked) {
          target.checked = false;
        }
      });

      window.itemIds = [];
      window.folderIds = [];
    }
    this.updateIdsUrl();
    this.toggleButtons();
  }

  checkbox() {
    const id = parseInt(event.target.value);

    if (event.target.checked) {
      switch (event.target.dataset.itemType) {
        case 'folder':
          window.folderIds.push(id);
          break;
        default:
          window.itemIds.push(id);
          break;
      }
    } else {
      switch (event.target.dataset.itemType) {
        case 'folder':
          var index = window.folderIds.indexOf(id);
          if (index !== -1) {
            window.folderIds.splice(index, 1);
          }
          break;
        default:
          var index = window.itemIds.indexOf(id);
          if (index !== -1) {
            window.itemIds.splice(index, 1);
          }
          break;
      }
    }
    this.updateIdsUrl();
    this.toggleButtons();
  }

  populateIds() {
    this.checkboxTargets.forEach(target => {
      if (target.checked) {
        if (target.dataset.itemType == 'folder') {
          window.itemIds.push(parseInt(target.value))
        } else {
          window.folderIds.push(parseInt(target.value))
        }
      }
    });
    this.updateIdsUrl();
    this.toggleButtons();
  }

  updateIdsUrl() {
    if (window.itemIds.length === 0 && window.folderIds.length === 0) {
      this.moveButtonTarget.href = '';
      this.moveButtonTarget.style.opacity = 0.7;
      this.moveButtonTarget.classList.add('cursor-not-allowed');
    } else {
      let moveUrl = this.moveUrlValue + '?item_ids=' + window.itemIds +  '&folder_ids=' + window.folderIds + '&type=' + this.modelNameValue;
      if(this.folderIdValue) {
        moveUrl += '&folder_id=' + this.folderIdValue  
      }
      this.moveButtonTarget.href = moveUrl;
      this.moveButtonTarget.style.opacity = 1;
      this.moveButtonTarget.classList.remove('cursor-not-allowed');
    }
  }

  toggleButtons() {
    if (window.itemIds.length === 0 && window.folderIds.length === 0) {
      this.actionsContainerTarget.style.visibility = 'hidden';
    } else {
      this.actionsContainerTarget.style.visibility = 'visible';
    }
  }
}