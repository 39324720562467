import { Controller } from "stimulus"

export default class extends Controller {
  // inputArea Target: the input area the user is typing to count characters
  // counter Target: the html tag that will host the text of the character count (e.g. 100/250)
  // characterNumber Value: the total number of characters allowed
  static targets = ["inputArea", "counter"]

  static values = {
    characterNumber: Number
  }

  initialize() {
    this.characterChecker();
  }

  characterChecker() {
    let numOfEnteredChars = this.inputAreaTarget.value.length;
    let totalChars = this.characterNumberValue
    let remainingChars = this.characterNumberValue - numOfEnteredChars;
    let warningThreshold = (totalChars * 0.2); // 20% of the total characters allowed

    this.counterTarget.textContent = remainingChars + "/" + totalChars;

    if (remainingChars < 0) {
      this.counterTarget.style.color = "red";
    } else if (remainingChars <= warningThreshold) {
      this.counterTarget.style.color = "orange";
    } else {
      this.counterTarget.style.color = "gray";
    }
  }
}