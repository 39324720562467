import { Controller } from "stimulus"

export default class extends Controller {

  static values = {
    primaryColor: String, 
    secondaryColor: String,
    tertiaryColor: String,
    background: String
  }

  static targets = [
    "bgPrimaryColor", "textPrimaryColor", "primaryColorInput",
    "bgSecondaryColor", "textSecondaryColor", "secondaryColorInput",
    "bgTertiaryColor", "textTertiaryColor", "tertiaryColorInput",
    "castPreview", "bgButton", "bgInput"
  ]

  connect() {
    this.primaryColorInputTarget.value = this.primaryColorValue;
    this.secondaryColorInputTarget.value = this.secondaryColorValue;
    this.tertiaryColorInputTarget.value = this.tertiaryColorValue;
  }

  setBackground(event) {
    const bgUrl = event.target.dataset.background;
    const bgId = event.target.dataset.id;

    this.castPreviewTarget.style.backgroundImage = `url(${bgUrl})`;
    this.bgInputTarget.value = bgId;
  }
  
  setPreview() {
    
  }

  setPrimaryColor(e) {
    this.bgPrimaryColorTargets.map(target => { target.style.backgroundColor = e.target.value });
    this.textPrimaryColorTargets.map(target => { target.style.color = e.target.value });
  }

  setSecondaryColor(e) {
    this.bgSecondaryColorTargets.map(target => { target.style.backgroundColor = e.target.value });
    this.textSecondaryColorTargets.map(target => { target.style.color = e.target.value });
  }

  setTertiaryColor(e) {
    this.bgTertiaryColorTargets.map(target => { target.style.backgroundColor = e.target.value });
    this.textTertiaryColorTargets.map(target => { target.style.color = e.target.value });
  }
}
