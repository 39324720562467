import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["directionInput", "directionButton"]

  toggleDirection() {
    const newDirection = this.directionInputTarget.value === "asc" ? "desc" : "asc";

    this.directionInputTarget.value = newDirection;
    this.directionButtonTarget.innerHTML = newDirection === "asc" ? '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"> <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" /></svg>' : '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"> <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" /></svg>';
  }

  autoSubmit(e) {
    e.target.form.requestSubmit();
  }
}
