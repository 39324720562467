import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["selectItemButton", "unselectItemButton", "availableItem", "selectedItem", "availableContainer", "selectedContainer",
    "emptyAvailableMessage", "emptySelectedMessage", "pageInput", "queryInput", "selectedScreensInput", "paginationAuxForm", "searchAuxForm", "selectAllFilterAuxForm"]

    static values = {
      filteredScreenIds: String
    };

  changePage(e){
    e.preventDefault();

    let pageNumber = e.target.getAttribute("href");
    if (pageNumber === "#") {} 
    else {
      pageNumber = pageNumber.split("page=")
      this.pageInputTarget.value = pageNumber.slice(-1);
      this.paginationAuxFormTarget.requestSubmit();
    }
  }

  search(e) {
    if (e.key === "Enter") { 
      e.preventDefault()
      return 
    }

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.queryInputTargets.forEach(target => {
        target.value = e.target.value.trim().toLowerCase();
      })
      
      this.searchAuxFormTarget.requestSubmit();
    }, 1500);
  }

  selectAllFiltered(e) {
    this.selectedScreensInputTargets.forEach(target => {
      target.value += target.value ? ',' + e.target.dataset.screens : e.target.dataset.screens;
    });

    this.selectAllFilterAuxFormTarget.requestSubmit();
  }

  unselectAll() {
    this.selectedScreensInputTargets.forEach(target => {
      target.value = '';
    });
  }

  select(e) {
    this.emptySelectedMessageTarget.classList.add("hidden");

    if (this.availableItemTargets.length <= 1) {
      this.emptyAvailableMessageTarget.classList.remove("hidden");
    }

    const availableItem = this.availableItemTargets.find(item => e.target.dataset.id === item.dataset.id);
    const unselectButton = this.unselectItemButtonTargets.find(button => e.target.dataset.id === button.dataset.id);
    const selectButton = e.target;
    const checkbox = availableItem.querySelector('input[type="checkbox"]');
    const selectedIds = this.selectedScreensInputTarget.value ? this.selectedScreensInputTarget.value.split(',') : []

    selectedIds.push(e.target.dataset.id);

    this.selectedScreensInputTargets.forEach(target => {
      target.value = selectedIds.join(",");
    });

    availableItem.dataset.itemSelectorTarget = "selectedItem";
    this.selectedContainerTarget.appendChild(availableItem);
    checkbox.checked = true;
    selectButton.classList.add("hidden");
    unselectButton.classList.remove("hidden");
  }

  unselect(e) {
    this.emptyAvailableMessageTarget.classList.add("hidden");

    if (this.selectedItemTargets.length <= 1) {
      this.emptySelectedMessageTarget.classList.remove("hidden");
    }
    
    const selectedItem = this.selectedItemTargets.find(item => e.target.dataset.id === item.dataset.id);
    const selectButton = this.selectItemButtonTargets.find(button => e.target.dataset.id === button.dataset.id);
    const unselectButton = e.target;
    const checkbox = selectedItem.querySelector('input[type="checkbox"]');
    let selectedIds = this.selectedScreensInputTarget.value.split(",")
    
    selectedIds = selectedIds.filter(item => item !== checkbox.value);

    this.selectedScreensInputTargets.forEach(target => {
      target.value = selectedIds.join(",");
    });

    selectedItem.dataset.itemSelectorTarget = "availableItem";
    this.availableContainerTarget.appendChild(selectedItem);
    checkbox.checked = false;
    selectButton.classList.remove("hidden");
    unselectButton.classList.add("hidden");
  }
}