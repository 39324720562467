import { Controller } from "stimulus"
import debounce from "lodash.debounce"

export default class extends Controller {
  static values = {
    delay: Number
  }

  initialize() {
    this.save = this.save.bind(this)
  }

  connect() {
    if (this.delayValue > 0) {
      this.save = debounce(this.save, this.delayValue)
    }
  }

  save() {
    if (window._rails_loaded) {
      // @ts-ignore
      Rails.fire(this.element, "submit")
    } else {
      this.element.requestSubmit()
    }
  }
}
