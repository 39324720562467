// https://www.stimulus-components.com/docs/stimulus-reveal-controller/
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item", "toggleIcon", "resetWhenToggle"]
  static classes = ["hidden"]

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : "hidden";
  }

  toggle() {
    if (this.hasToggleIconTarget) {
      this.toggleIconTargets.forEach(item => {
        item.classList.toggle(this.class);
      });
    }
    
    this.itemTargets.forEach(item => {
      if (this.resetWhenToggleTargets) {
        this.resetItemsValue(this.resetWhenToggleTargets)
      }

      item.classList.toggle(this.class);
    });
  }

  show() {
    this.itemTargets.forEach(item => {
      item.classList.remove(this.class);
    });
  }

  hide() {
    this.itemTargets.forEach(item => {
      item.classList.add(this.class);
    });
  }

  resetItemsValue(items) {
    items.forEach(item => {
      if (item.tagName == "INPUT") {
        item.value = '';
      } 
      else {
        item.innerText = '';
      }
    });
  }
}
